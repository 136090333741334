<template lang="html">
  <transition name="fade">
    <div id="pagetop" v-show="scY > 300">
      <a href="#" class="btn"><span class="caption">Top</span></a>
    </div>
  </transition>
</template>

<script>
import { getStrapiUrl } from "../../helper";
export default {
  name: "BackToTop",
  data() {
    return {
      url: getStrapiUrl(),
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
  },
};
</script>

<style lang="css" scoped>
#pagetop {
  width: 5rem;
  height: 5rem;
  background-color: var(--tattoo-darker-gray);
  position: fixed;
  right: 5rem;
  margin-bottom: 3rem;
  bottom: 3rem;
  z-index: 100;
}

.btn {
  background: url(/assets/graphics/image-gallery-arrow.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 30%;
  background-position: center;
  border: none;
  text-decoration: none;
  display: inline-block;
  z-index: 999;
  width: 100%;
  height: 100%;
  transform: rotate(270deg);
}

.caption {
  display: none;
}

#pagetop .btn:focus {
  outline: none;
  box-shadow: none;
}

#pagetop a:hover {
  background-color: var(--tattoo-light-turquoise);
}

#pagetop:hover .btn {
  background-position: 2.6rem;
}

#pagetop:hover .caption {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 1.5rem;
  left: 0.5rem;
  transform: rotate(90deg);
  font-size: 1.5rem;
}

@media screen and (max-width: 56.25em) {
  #pagetop {
    right: 2rem;
    bottom: 0rem;
  }
}

@media screen and (max-width: 25em) {
  #pagetop {
    display: none;
  }
}
</style>
