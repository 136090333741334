<template>
  <section id="ctBoxes" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <div>
        <div class="row justify-content-between">
          <div
            v-show="box.content"
            v-for="box in body.box"
            :key="box.id"
            class="col col-xxl-auto box-row"
          >
            <div class="boxes sw-border-left">
              <vue-markdown :source="box.content"></vue-markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "ContentTypeBoxes",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.box-row {
  display: flex;
  justify-content: space-between;
}

.boxes {
  background-color: var(--tattoo-gray);
  padding: 2rem;
  text-align: center;
  flex-basis: 100%;
  min-width: 25rem;
  max-width: 40rem;
  min-height: 100%;
  width: 21vw;
  color: var(--tattoo-turquoise-gray);
}

@media (max-width: 1653px) {
  #ctBoxes .col-xxl-auto {
    flex: unset;
    width: unset;
  }
}

@media screen and (max-width: 63.8125em) {
  .box-row {
    margin-bottom: 2rem;
    width: 100%;
  }

  .boxes {
    max-width: 100%;
  }
}

@media screen and (max-width: 50em) {
  .boxes {
    width: 100%;
  }
}
</style>
