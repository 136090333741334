<template>
  <div id="app">
    <Nav />
    <BackToTop />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import BackToTop from "./components/element/BackToTop.vue";
export default {
  name: "App",
  components: {
    Nav,
    BackToTop,
  },
};
</script>
