<template>
  <section id="landingHero">
    <div id="landing-desktop" class="bg-darkGray position-relative background">
      <div
        v-for="(text, index) in landingText"
        :key="text.id"
        v-show="index === currIndex"
      >
        <div
          class="bg-img position-absolute"
          :style="{
            backgroundImage: `linear-gradient(90deg, #1F1F1F 42%, rgba(30, 30, 30, 0) 100%), url(${
              url + text.backgroundImage.data.attributes.url
            })`,
          }"
          v-show="index === currIndex"
        ></div>

        <div class="row position-relative">
          <div class="col-auto d-flex align-items-center">
            <div
              class="socialmedia-wrapper position-relative text-center bg-darkGray"
            >
              <div
                class="bg-darkGray socialmedia-wrapper-inner position-relative"
              >
                <a :href="navData.facebook" target="_blank"
                  ><i class="fa fa-brands fa-facebook-f text-white"></i
                ></a>
                <br />
                <a :href="navData.instagram" target="_blank"
                  ><i class="fa fa-brands fa-instagram text-white"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col">
            <img
              :src="url + logo.data.attributes.url"
              :alt="logo.data.attributes.alternativeText"
              class="logo"
            />
            <h1>{{ text.headline }}</h1>
            <article class="text-white info-text">
              <vue-markdown :source="text.text" />
            </article>
            <Button :button="text.link" class="cta-link" />
          </div>
          <div class="col-auto">
            <div class="d-flex justify-content-center h-100 flex-column">
              <Button
                v-for="link in navData.navLink"
                :key="link.id"
                :button="{
                  ...link,
                  style: 'link',
                }"
                class="text-end nav-link"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row position-relative justify-content-between carousel"
      >
        <div class="d-flex align-items-end pagination">
          <div
            v-for="n in landingText.length"
            :key="n"
            class="text-white m-4"
            role="button"
            @click="currIndex = n - 1"
          >
            <img
              v-if="currIndex + 1 === n"
              src="/assets/graphics/pagination-active.svg"
              alt="Pagination dot"
            />
            <img
              v-else
              class="pagination-hover"
              src="/assets/graphics/pagination-normal.svg"
              alt="Pagination dot"
            />
          </div>
          <div class="meter">
            <span><span class="progress"></span></span>
          </div>
        </div>
        <div>
          <div
            v-for="(carousel, index) in landingCarousel"
            :key="carousel.id"
            v-show="index === currCarouselIndex"
          >
            <div class="d-flex">
              <img
                v-for="img in carousel.images.data"
                :key="img.id"
                :src="url + img.attributes.url"
                :alt="img.attributes.alternativeText"
                class="carousel-img"
              />
              <div class="d-flex bg-darkGray carousel-info-control-wrapper">
                <div
                  class="d-flex flex-column justify-content-between carousel-info-wrapper"
                >
                  <h6 class="carousel-headline">{{ carousel.headline }}</h6>
                  <Button
                    :button="{
                      page: carousel.seeMore,
                      url: null,
                      label: 'Mehr erfahren',
                      style: 'link',
                    }"
                  />
                </div>
                <div class="carousel-controls-wrapper">
                  <img
                    src="/assets/graphics/image-gallery-arrow.svg"
                    alt="Arrow"
                    class="bg-darkGray fa-rotate-180"
                    @click="
                      index > 0
                        ? currCarouselIndex--
                        : (currCarouselIndex = landingCarousel.length - 1)
                    "
                  />
                  <img
                    src="/assets/graphics/image-gallery-arrow.svg"
                    alt="Arrow"
                    class="bg-darkGray"
                    @click="
                      index === landingCarousel.length - 1
                        ? (currCarouselIndex = 0)
                        : currCarouselIndex++
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="landing-mobile" class="bg-darkGray">
      <div
        v-for="(text, index) in landingText"
        :key="text.id"
        v-show="index === currIndex"
        class="position-relative"
      >
        <div
          class="bg-img position-absolute"
          :style="{
            backgroundImage: `linear-gradient(360deg, #1F1F1F 0%, rgba(30, 30, 30, 0) 100%), url(${
              url + text.backgroundImage.data.attributes.url
            })`,
          }"
          v-show="index === currIndex"
        ></div>

        <div class="position-relative">
          <div class="ls-mobile-inner">
            <img
              :src="url + logo.data.attributes.url"
              :alt="logo.data.attributes.alternativeText"
              class="logo"
            />
            <div class="d-flex align-items-end pagination">
              <div
                v-for="n in landingText.length"
                :key="n"
                class="text-white m-4"
                :class="{ 'ms-0': n === 1 }"
                role="button"
                @click="currIndex = n - 1"
              >
                <img
                  v-if="currIndex + 1 === n"
                  src="/assets/graphics/pagination-active.svg"
                  alt="Pagination dot"
                />
                <img
                  class="pagination-hover"
                  v-else
                  src="/assets/graphics/pagination-normal.svg"
                  alt="Pagination dot"
                />
              </div>
              <div class="meter">
                <span><span class="progress"></span></span>
              </div>
            </div>
            <h1>{{ text.headline }}</h1>
            <article class="text-white info-text">
              <vue-markdown :source="text.text" />
            </article>
            <Button :button="text.link" class="cta-link" />
          </div>
        </div>
      </div>
      <div
        v-for="(carousel, index) in landingCarousel"
        :key="carousel.id"
        v-show="index === currCarouselIndex"
        class="ls-mobile-inner"
      >
        <div class="d-flex flex-column">
          <div class="carousel-controls-wrapper">
            <div class="d-flex carousel-head-mobile">
              <h6 class="carousel-headline">{{ carousel.headline }}</h6>
              <div>
                <img
                  src="/assets/graphics/image-gallery-arrow.svg"
                  alt="Arrow"
                  class="bg-darkGray fa-rotate-180"
                  @click="
                    index > 0
                      ? currCarouselIndex--
                      : (currCarouselIndex = landingCarousel.length - 1)
                  "
                />
                <img
                  src="/assets/graphics/image-gallery-arrow.svg"
                  alt="Arrow"
                  class="bg-darkGray"
                  @click="
                    index === landingCarousel.length - 1
                      ? (currCarouselIndex = 0)
                      : currCarouselIndex++
                  "
                />
              </div>
            </div>
          </div>
          <div class="flex">
            <img
              v-for="img in carousel.images.data"
              :key="img.id"
              :src="url + img.attributes.url"
              :alt="img.attributes.alternativeText"
              class="carousel-img medium-size"
            />
          </div>
          <img
            :src="url + carousel.images.data[0].attributes.url"
            :alt="carousel.images.data[0].attributes.alternativeText"
            class="carousel-img small-size"
          />
          <div class="d-flex bg-darkGray carousel-info-control-wrapper">
            <div
              class="d-flex flex-column justify-content-between carousel-info-wrapper"
            >
              <Button
                :button="{
                  page: carousel.seeMore,
                  url: null,
                  label: 'Mehr erfahren',
                  style: 'link',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getStrapiUrl } from "../helper";
import Button from "./element/Button.vue";
import VueMarkdown from "vue-markdown";
export default {
  name: "LandingHeroSection",
  data() {
    return {
      url: getStrapiUrl(),
      currIndex: 0,
      currCarouselIndex: 0,
    };
  },
  components: {
    Button,
    VueMarkdown,
  },
  props: {
    landingText: {
      type: Array,
      required: true,
    },
    landingCarousel: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      required: true,
    },
    navData: {
      type: Object,
      required: true,
    },
  },
  mounted: function () {
    window.setInterval(() => {
      this.currIndex += 1;
      if (this.currIndex == this.landingText.length) {
        this.currIndex = 0;
      }
    }, 10000);
  },
};
</script>

<style scoped>
.meter {
  position: absolute;
  top: 22rem;
  left: 8.5rem;
  height: 0.7rem;
  width: 12rem;
  background-color: var(--tattoo-light-gray);
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.progress {
  background-color: var(--tattoo-light-turquoise);
  animation: progressBar 10s ease-in-out;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

#landing-mobile {
  display: none;
}

.logo {
  margin-top: 10rem;
  margin-bottom: 6rem;
  width: 60rem;
  position: relative;
  z-index: 801;
}

.info-text {
  margin-bottom: 3rem;
}

.cta-link a {
  display: block;
}
.cta-link button {
  padding: 0;
}

.cta-link {
  margin-bottom: 3rem;
}

.background {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  height: 100%;
  width: 100%;
  right: 0;
}

.socialmedia-wrapper::before {
  position: absolute;
  content: "";
  width: 0.2rem;
  height: 125%;
  background-color: white;
  left: 8.5rem;
  bottom: -19rem;
}

.socialmedia-wrapper .fa {
  font-size: 4rem;
  margin-left: 7rem;
  margin-right: 10rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.socialmedia-wrapper-inner {
  padding: 5rem 0;
  margin-top: 25rem;
}

/** Navigation **/

.nav-link {
  padding-right: 6rem !important;
  padding-left: 15vw !important;
}

/** Carousel **/

.carousel {
  padding-bottom: 10rem;
}

.pagination {
  margin-left: 7rem;
}

.pagination-hover:hover {
  background: url(/assets/graphics/pagination-hover.svg) no-repeat;
}

.carousel-headline {
  font-size: 5.5rem;
  color: white;
}

.carousel-img {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  object-position: center;
  margin: 0 3rem;
}

.carousel-info-wrapper {
  margin: 2.5rem 10rem 3rem 4rem;
  max-width: 20rem;
  min-width: 20rem;
}

.carousel-controls-wrapper {
  padding: 3rem 4rem 0 0;
}

.carousel-controls-wrapper img {
  margin: 0 1.5rem;
}

.carousel-controls-wrapper img:hover {
  cursor: pointer;
}

@media screen and (max-width: 999em) {
  .meter {
    width: 10rem;
  }
}

@media screen and (max-width: 175em) {
  .meter {
    width: 12rem;
  }
}

@media screen and (max-width: 106.25em) {
  .meter {
    top: 22rem;
  }

  .logo {
    margin-top: 7rem;
    margin-bottom: 3rem;
    width: 50rem;
  }

  .nav-link label {
    font-size: 3.5rem;
  }

  .carousel-headline {
    font-size: 4rem;
  }

  .carousel-info-wrapper {
    margin: 2.5rem 0 3rem 4rem;
  }
}

@media screen and (max-width: 87.5em) {
  .carousel-img {
    width: 15rem;
    height: 15rem;
    margin: 0 1rem;
  }
}

@media screen and (max-width: 87em) {
  .meter {
    top: 17rem;
  }
}

@media screen and (max-width: 71.875em) {
  .logo {
    margin-top: 4rem;
    margin-bottom: 2rem;
    width: 40rem;
  }

  .socialmedia-wrapper .fa {
    font-size: 3rem;
    margin-left: 3.5rem;
    margin-right: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .socialmedia-wrapper-inner {
    padding: 2rem 0;
  }

  .socialmedia-wrapper::before {
    left: 5rem;
  }

  .nav-link {
    padding-right: 3rem !important;
    padding-left: 6rem !important;
  }

  .nav-link label {
    font-size: 3rem;
  }

  .carousel-headline {
    font-size: 3rem;
  }

  .carousel-controls-wrapper img {
    width: 1.5rem;
  }

  .carousel-info-wrapper {
    margin: 2rem 0 2rem 2.5rem;
  }

  .carousel-info-wrapper {
    max-width: 15rem;
    min-width: 15rem;
  }
}

@media screen and (max-width: 62.5em) {
  .meter {
    position: absolute;
    top: 54rem;
    left: unset;
    margin-bottom: 20rem;
  }

  #landing-desktop {
    display: none;
  }

  #landing-mobile {
    display: block;
  }

  .small-size {
    display: none;
  }

  .bg-img {
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .logo {
    width: 40rem;
    margin: 5rem;
    margin-bottom: 1rem;
  }

  .ls-mobile-inner {
    padding: 0 4rem;
  }

  .carousel-info-wrapper {
    margin: 2rem 0;
  }

  .carousel-head-mobile {
    margin-bottom: 2rem;
  }

  .carousel-img:first-of-type {
    margin-left: 0;
  }

  .pagination {
    margin: 0;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 37.5em) {
  .meter {
    position: relative;
    top: 22rem;
    left: -13.5rem;
    min-width: 12rem;
  }

  .ls-mobile-inner {
    padding: 0 2.4rem;
  }

  .small-size {
    display: block;
  }

  .medium-size {
    display: none;
  }

  .carousel-img {
    width: 100%;
    height: 20rem;
    margin: 0;
  }

  .carousel-head-mobile {
    justify-content: space-between;
  }

  .carousel-controls-wrapper {
    padding-right: 0;
  }

  .logo {
    width: 100%;
    margin: 0;
    padding: 3rem 0;
    padding-bottom: 2rem;
    padding-right: 20vw;
  }
}
</style>
