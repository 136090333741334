<template>
  <main id="contact" v-if="contact.data.attributes">
    <div v-for="(body, index) in contact.data.attributes.body" :key="body.id">
      <div v-if="body.__typename === 'ComponentContentTypesCtContact'">
        <ContentTypeContact :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtHeroSection'">
        <ContentTypeHeroSection :body="body" :pos="index" />
      </div>
    </div>
    <div class="bg-darkGray">
      <div v-if="submitstatus == 'SUCCESS'">
        <div class="popup-container">
          <div class="popup-img">
            <lord-icon
              v-if="submitsuccess"
              delay="600000"
              trigger="loop"
              src="/assets/graphics/contact-checkmark-true.json"
            ></lord-icon>
            <lord-icon
              v-else
              delay="600000"
              trigger="loop"
              src="/assets/graphics/contact-checkmark-false.json"
            ></lord-icon>
          </div>
          <div class="popup-text text">
            <div v-if="submitsuccess">
              <h3 class="popup-head text-white">
                Anfrage wurde erfolgreich zugestellt
              </h3>
              <p class="popup-body text-white">
                Vielen Dank für Ihre Nachricht, wir werden uns unverzüglich bei
                Ihnen melden.
              </p>
            </div>
            <div v-else>
              <h3 class="popup-head text-white">
                Anfrage wurde nicht zugestellt
              </h3>
              <p class="popup-body text-white">
                Etwas ist schiefgelaufen. Bitte veruschen Sie es erneut.
              </p>
            </div>
          </div>
          <button class="popup-btn" @click="closePopUp"></button>
        </div>
      </div>
      <div>
        <div class="container" v-if="contact.data.attributes.content">
          <article>
            <vue-markdown
              :source="contact.data.attributes.content"
            ></vue-markdown>
          </article>
        </div>
        <div class="container">
          <div class="input-container">
            <label
              class="text"
              :class="{
                'is-invalid': $v.firstname.$error,
              }"
              >Vorname*
              <input
                id="vname"
                class="text"
                type="text"
                name="firstname"
                placeholder="Vorname"
                v-model.trim="$v.firstname.$model"
              />
            </label>
            <div class="invalid-feedback">
              <span v-if="!$v.firstname.required"
                >Das ist ein Pflichtfeld!
              </span>
            </div>
          </div>
          <br />
          <div class="input-container">
            <label
              class="text"
              :class="{
                'is-invalid': $v.name.$error,
              }"
              >Name*
              <input
                id="name"
                class="text"
                type="text"
                name="lastname"
                placeholder="Name"
                v-model.trim="$v.name.$model"
              />
            </label>
            <div class="invalid-feedback">
              <span v-if="!$v.name.required">Das ist ein Pflichtfeld! </span>
            </div>
          </div>
          <br />
          <div class="input-container">
            <label class="text"
              >Geburtstag
              <input
                id="date"
                class="text"
                type="text"
                name="birthday"
                placeholder="Geburtstag"
                v-model="birthday"
              />
            </label>
          </div>
          <br />
          <div class="input-container">
            <label
              class="text"
              :class="{
                'is-invalid': $v.email.$error,
              }"
              >E-Mail*
              <input
                id="email"
                class="text"
                type="text"
                name="emailadress"
                placeholder="E-Mail"
                v-model.trim="$v.email.$model"
              />
            </label>
            <div class="invalid-feedback">
              <span v-if="!$v.email.required">Das ist ein Pflichtfeld!</span>
            </div>
          </div>
          <br />
          <div class="input-container">
            <label class="text"
              >Telefon
              <input
                id="phone"
                class="text"
                type="tel"
                name="phonennumber"
                placeholder="Telefon"
                v-model.trim="phonenumber"
            /></label>
          </div>
          <br />
          <div class="input-container">
            <label
              class="text"
              :class="{
                'is-invalid': $v.message.$error,
              }"
              >Nachricht*
              <div class="textarea-container">
                <textarea
                  id="message"
                  class="text"
                  maxlength="500"
                  placeholder="Schreib uns eine Nachricht"
                  v-model.trim="$v.message.$model"
                ></textarea>
                <div class="charcount-container">
                  <span class="charcount text">{{ message.length }}</span>
                  <span class="charcount text">/{{ maxcharacter }}</span>
                </div>
              </div>
            </label>
            <div class="invalid-feedback">
              <span v-if="!$v.message.required">Das ist ein Pflichtfeld! </span>
            </div>
            <br />
          </div>
          <br />
          <label class="text privacy-text">
            <input
              id="privacy"
              type="checkbox"
              v-model.trim="$v.privacy.$model"
              :class="{
                'is-invalid': $v.privacy.$error,
              }"
            />
            Hiermit bestätigen Sie unsere Datenschutzbestimmungen. Mehr
            Informationen finden Sie in der
            <Button :button="contact.data.attributes.link" />
            <div class="invalid-feedback">
              <span id="invalid-checkbox" v-if="!$v.privacy.sameAs"
                >Das ist ein Pflichtfeld!</span
              >
            </div>
          </label>
          <label class="text lbl-privacy">* Pflichtfelder</label>
          <button
            id="submit"
            @click.prevent="submitForm"
            type="submit"
            class="btn-primary-sw"
          >
            Absenden
          </button>
        </div>
      </div>
    </div>
    <FooterSmall :legalLinks="footer.legalLinks" />
  </main>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import FooterSmall from '../components/FooterSmall.vue';
import axios from 'axios';
import { required, email, maxLength, sameAs } from 'vuelidate/lib/validators';
import Vue from 'vue';
import ContentTypeContact from '../components/ContentTypeContact.vue';
import ContentTypeHeroSection from '../components/ContentTypeHeroSection.vue';
import Vuelidate from 'vuelidate';
import Button from '../components/element/Button.vue';
Vue.use(Vuelidate);

export default {
  name: 'Contact',
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
    ContentTypeHeroSection,
    ContentTypeContact,
    FooterSmall,
    Button,
  },
  data() {
    return {
      contact: {},
      footer: {},
      firstname: '',
      name: '',
      date: '',
      birthday: '',
      email: '',
      phonenumber: '',
      message: '',
      privacy: false,
      submitstatus: null,
      submitsuccess: false,
      maxcharacter: 500,
      remaincharactersText: '0',
    };
  },
  validations: {
    firstname: {
      required,
    },
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    message: {
      required,
      maxLength: maxLength(500),
    },
    privacy: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  apollo: {
    contact: {
      query: require('../graphql/Contact.gql'),
      result(result) {
        this.contact = result.data.contact;
        this.footer = result.data.footer.data.attributes;
      },
    },
  },
  methods: {
    submitForm: function () {
      this.$v.$touch();
      if (this.$v.$error) {
        this.submitstatus = 'FAIL';
      } else {
        axios
          .post('/api/mail', {
            firstName: this.firstname,
            name: this.name,
            birthday: this.birthday,
            email: this.email,
            tel: this.phonenumber,
            message: this.message,
            recipient: this.contact.data.attributes.recipient,
          })
          .then((response) => {
            if (response.data.status === 'success') {
              this.submitsuccess = true;
            } else {
              this.submitsuccess = false;
            }
          })
          .catch(function () {
            this.submitsuccess = false;
          })
          .finally(() => (this.submitstatus = 'SUCCESS'));
      }
    },
    closePopUp: function () {
      this.submitsuccess = false;
      this.submitstatus = 'FAIL';
    },
  },
};
</script>

<style lang="css" scoped>
.text {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
}

h3 {
  font-family: 'Oswald', sans-serif !important;
  text-decoration: none !important;
}

label {
  color: var(--tattoo-white);
  width: 100%;
}

input {
  color: var(--tattoo-white);
  width: 100%;
  height: 6rem;
  border: none;
  background-color: var(--tattoo-gray);
  padding-left: 3rem;
  font-size: 3rem;
  border-left: 0.6rem solid var(--tattoo-gray);
}

input:focus {
  border-left: 0.6rem solid var(--tattoo-light-turquoise);
}

textarea {
  padding-left: 3rem;
  padding-top: 1.5rem;
  width: 100%;
  height: 36rem;
  background-color: var(--tattoo-gray);
  color: var(--tattoo-white);
  border: none;
  resize: none;
  font-size: 3rem;
  border-left: 0.6rem solid var(--tattoo-gray);
}

textarea:focus {
  border-left: 0.6rem solid var(--tattoo-light-turquoise);
}

*:focus {
  outline: none;
}

.is-invalid {
  color: var(--tattoo-error);
}

.is-invalid textarea {
  border: 0.1rem solid var(--tattoo-error);
}

.is-invalid input {
  border: 0.1rem solid var(--tattoo-error);
}

::placeholder {
  color: var(--tattoo-light-gray);
  font-size: 3rem;
}

.textarea-container {
  position: relative;
}

.charcount-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.charcount {
  color: var(--tattoo-white);
}

.checkbox-container {
  text-align: left;
  margin-bottom: 3rem;
}

.privacy-text {
  vertical-align: top;
}

a {
  color: var(--tattoo-light-turquoise);
}

#privacy {
  zoom: 1.5;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
}

.lbl-privacy {
  margin-top: 3rem;
}

input[type='checkbox'] {
  color: unset;
  width: unset;
  height: unset;
  border: unset;
  background-color: unset;
}

.input-container {
  margin-bottom: -1rem;
}

.invalid-feedback {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
}

#invalid-checkbox {
  font-size: 1.75rem;
}

#submit {
  border: none;
}

.popup-container {
  position: fixed;
  display: flex;
  top: 10%;
  left: 50%;
  margin-top: -1.3rem;
  margin-left: -40rem;
  z-index: 999;
  width: 80rem;
  height: 13rem;
  background-color: var(--tattoo-darker-gray);
  border-radius: 1rem;
  align-items: center;
}

.popup-img {
  margin-top: -1.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
  zoom: 2;
}

.popup-head {
  font-size: 3rem !important;
  font-weight: bold;
}

.popup-body {
  font-size: 2rem !important;
}

.popup-btn {
  background: url(/assets/graphics/image-gallery-cross.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 3rem 3rem;
  background-position: center;
  border: none;
  padding: 1rem 1rem;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  top: 4%;
  right: 1%;
  width: 4rem;
  height: 4rem;
}

@media screen and (max-width: 51.375em) {
  .popup-container {
    display: unset;
    margin-left: -25rem;
    width: 50rem;
    height: 20rem;
    text-align: center;
  }

  .popup-img {
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    zoom: 2;
  }

  .popup-text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 36em) {
  input {
    height: 5rem;
    font-size: 2.5rem;
    padding-left: 2rem;
  }

  textarea {
    height: 30rem;
    font-size: 2.5rem;
    padding-left: 2rem;
    padding-top: 1rem;
  }

  ::placeholder {
    font-size: 2.5rem;
  }

  .input-container {
    margin-bottom: -2rem;
  }

  .charcount-container {
    font-size: 2rem;
  }

  #privacy {
    margin-right: 1rem;
  }

  .popup-container {
    margin-left: -15rem;
    width: 30rem;
    height: 26rem;
  }

  .popup-head {
    font-size: 2.5rem !important;
  }

  .popup-body {
    font-size: 2rem !important;
  }
}
@media screen and (max-width: 20em) {
  input {
    height: 4rem;
    font-size: 2rem;
    padding-left: 1rem;
  }

  textarea {
    height: 24rem;
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
  }

  ::placeholder {
    font-size: 2rem;
  }

  .input-container {
    margin-bottom: -2rem;
  }

  .charcount-container {
    font-size: 1.5rem;
  }

  #privacy {
    margin-right: 0.5rem;
  }

  .popup-container {
    margin-left: -10rem;
    width: 20rem;
    height: 32rem;
  }
}
</style>
