var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bg-darkGray",attrs:{"id":"ctFeedback"}},[_c('div',{staticClass:"container"},[(_vm.body.headline)?_c('div',[_c('h2',{staticClass:"headline"},[_c('span',[_vm._v(_vm._s(_vm.body.headline))])])]):_vm._e(),_c('Carousel',{attrs:{"perPageCustom":[
        [0, 1],
        [1024, 2],
        [1200, 3] ],"navigateTo":_vm.page,"paginationEnabled":false},on:{"page-change":_vm.pageChanged}},_vm._l((_vm.body.feedback),function(feedback,index){return _c('Slide',{key:feedback.id},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(feedback.rating >= 0),expression:"feedback.rating >= 0"}],staticClass:"boxes"},[_c('div',{staticClass:"star-wrapper"},[_vm._l((_vm.stars(feedback.rating)),function(index){return _c('img',{key:index,attrs:{"src":"/assets/graphics/colored-star.svg","alt":"Colored Star"}})}),(_vm.isDecimal(feedback.rating))?_c('img',{attrs:{"src":"/assets/graphics/half-colored-star.svg","alt":"Half Colored Star"}}):_vm._e(),_vm._l((_vm.notColoredStars(feedback.rating)),function(index){return _c('img',{key:index,attrs:{"src":"/assets/graphics/star.svg","alt":"Colored Star"}})})],2),_c('div',{staticClass:"text-center text-container"},[_c('div',{staticClass:"inner-text"},[(feedback.content)?_c('vue-markdown',[_vm._v(_vm._s(_vm._f("truncate")(feedback.content,90, "..."))+" ")]):_vm._e(),(feedback.content.length >= 90)?_c('button',{staticClass:"btn-link-sw button my-3 mx-auto",on:{"click":function($event){return _vm.openOverlay(
                    feedback.rating,
                    feedback.content,
                    feedback.name
                  )}}},[_c('label',[_vm._v(" Mehr lesen ")])]):_vm._e(),(feedback.name)?_c('vue-markdown',{attrs:{"source":feedback.name}}):_vm._e()],1)]),_c('span',{staticClass:"marks"},[_vm._v(",,")])]),_c('div',{staticClass:"pageText"},[_vm._v(" "+_vm._s(index + 1)+" von "+_vm._s(_vm.body.feedback.length)+" ")])])}),1),_c('div',{staticClass:"btnMobile"},[_c('button',{staticClass:"arrowImg arrow",attrs:{"id":"arrowLEFT"},on:{"click":function($event){return _vm.swapSlide('left')}}}),_c('button',{staticClass:"arrowImg arrow",attrs:{"id":"arrowRIGHT"},on:{"click":function($event){return _vm.swapSlide('right')}}})])],1),_c('Overlay',{ref:"overlay"},[_c('tempplate',[_c('div',[_c('div',{staticClass:"star-wrapper"},[_vm._l((_vm.stars(_vm.overlayRating)),function(index){return _c('img',{key:index,attrs:{"src":"/assets/graphics/colored-star.svg","alt":"Colored Star"}})}),(_vm.isDecimal(_vm.overlayRating))?_c('img',{attrs:{"src":"/assets/graphics/half-colored-star.svg","alt":"Half Colored Star"}}):_vm._e(),_vm._l((_vm.notColoredStars(_vm.overlayRating)),function(index){return _c('img',{key:index,attrs:{"src":"/assets/graphics/star.svg","alt":"Colored Star"}})})],2),_c('div',{staticClass:"text-center"},[_c('div',{},[(_vm.overlayText)?_c('vue-markdown',[_vm._v(_vm._s(_vm.overlayText)+" ")]):_vm._e(),_c('br'),(_vm.overlayName)?_c('vue-markdown',{attrs:{"source":_vm.overlayName}}):_vm._e()],1)]),_c('span',{staticClass:"marks"},[_vm._v(",,")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }