<template>
  <section id="ctText" class="bg-darkGray">
    <div v-if="body.content" class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <article class="content">
        <vue-markdown :source="body.content"></vue-markdown>
      </article>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "ContentTypeText",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.content {
  text-align: justify;
  color: var(--tattoo-turquoise-gray);
}
</style>
