<template>
  <section id="ctMerchGallery" class="bg-darkGray">
    <div class="container">
      <div class="row justify-content-between">
        <div
          v-for="merch in body.merch"
          :key="merch.id"
          class="col col-md-auto col-box"
        >
          <div
            class="box d-flex flex-column justify-content-between"
            @click="
              openOverlay(
                url + merch.image.data.attributes.url,
                merch.image.data.attributes.alternativeText,
                merch.title,
                merch.description,
                merch.price,
                merch.discountedPrice
              )
            "
          >
            <div>
              <div v-if="merch.flag" class="flag">
                <img
                  v-if="merch.flag === 'SOLDOUT'"
                  src="/assets/graphics/merch-gallery-soldout.svg"
                />
                <img
                  v-else-if="merch.flag === 'NEW'"
                  src="/assets/graphics/merch-gallery-new.svg"
                />
                <img
                  v-else-if="merch.flag === 'SALE'"
                  src="/assets/graphics/merch-gallery-sale.svg"
                />
              </div>
              <div class="img-container">
                <img
                  :src="url + merch.image.data.attributes.url"
                  :alt="merch.image.data.attributes.alternativeText"
                />
              </div>
              <div class="title-container">
                <p class="title">{{ merch.title }}</p>
              </div>
            </div>
            <div class="price-wrapper d-flex justify-content-between row">
              <div class="col"></div>
              <div
                class="price col d-flex align-items-center justify-content-center"
              >
                {{ merch.price }}&nbsp;€
              </div>
              <div
                class="col dis-price d-flex align-items-center justify-content-center"
              >
                <span v-if="merch.discountedPrice"
                  >{{ merch.discountedPrice }}&nbsp;€</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Overlay ref="overlay" scrollable>
      <template>
        <div class="row">
          <div class="col">
            <div class="img-container-overlay">
              <img :src="overlayImage" :alt="overlayImageAlt" />
            </div>
          </div>
          <div class="col ms-md-4">
            <div class="overlay-content-wrapper">
              <div class="title-container">
                <p class="title">{{ overlayTitle }}</p>
              </div>
              <vue-markdown class="my-5">
                {{ overlayDescription }}
              </vue-markdown>
              <div class="price-wrapper d-flex justify-content-between row">
                <div class="col"></div>
                <div
                  class="price col d-flex align-items-center justify-content-center"
                >
                  {{ overlayPrice }}&nbsp;€
                </div>
                <div
                  class="col dis-price d-flex align-items-center justify-content-center"
                >
                  <span v-if="overlayDiscountedPrice"
                    >{{ overlayDiscountedPrice }}&nbsp;€</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Overlay>
  </section>
</template>

<script>
import { getStrapiUrl } from "../helper";
import Overlay from "./element/Overlay.vue";
import VueMarkdown from "vue-markdown";

export default {
  name: "ContentTypeMerchGallery",
  data() {
    return {
      url: getStrapiUrl(),
      overlayImage: "",
      overlayImageAlt: "",
      overlayTitle: "",
      overlayDescription: "",
      overlayPrice: "",
      overlayDiscountedPrice: "",
    };
  },
  methods: {
    openOverlay(
      overlayImage,
      overlayImageAlt,
      overlayTitle,
      overlayDescription,
      overlayPrice,
      overlayDiscountedPrice
    ) {
      this.overlayImage = overlayImage;
      this.overlayImageAlt = overlayImageAlt;
      this.overlayTitle = overlayTitle;
      this.overlayDescription = overlayDescription;
      this.overlayPrice = overlayPrice;
      this.overlayDiscountedPrice = overlayDiscountedPrice;
      this.$refs.overlay.show();
    },
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    Overlay,
    VueMarkdown,
  },
};
</script>

<style scoped>
.col-box {
  margin-bottom: 10rem;
}

.img-container-overlay {
  height: 100%;
  min-width: 35rem;
}

.overlay-content-wrapper {
  min-width: 40rem;
}

.img-container-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.box {
  max-width: 40rem;
  min-width: 40rem;
  position: relative;
  background-color: var(--tattoo-gray);
  padding: 3rem;
  min-height: 100%;
  text-align: center;
  border-left: 0.5rem solid var(--tattoo-gray);
  padding-left: 2.5rem;
}

.box:hover {
  border-left: 0.5rem solid var(--tattoo-light-turquoise);
  cursor: pointer;
}

.flag {
  position: absolute;
  right: 0;
  top: 0;
}

.img-container {
  width: 100%;
  height: 39rem;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.price-wrapper div {
  font-size: 3rem;
  white-space: nowrap;
}

.price-wrapper div.dis-price {
  text-decoration: line-through;
  text-decoration-color: var(--tattoo-white);
  font-weight: 300;
  font-size: 1em;
  white-space: nowrap;
}

@media screen and (max-width: 999em) {
  .flag img {
    width: 20rem;
  }

  .img-container {
    height: 54rem;
  }
}

@media screen and (max-width: 140.8125em) {
  .img-container {
    height: 39rem;
  }
}

@media screen and (max-width: 112.6875em) {
  .flag img {
    width: 15rem;
  }

  .img-container {
    height: 29rem;
  }
}

@media screen and (max-width: 93.9375em) {
  .box {
    padding: 2rem;
  }

  .flag img {
    width: 10rem;
  }

  .img-container {
    height: 27rem;
  }
}

@media screen and (max-width: 87.5em) {
  .col-box {
    margin-bottom: 5rem;
  }

  .box {
    padding: 2rem;
  }

  .flag img {
    width: 12rem;
  }

  .img-container {
    height: 27rem;
  }

  .title-container {
    min-height: 10rem;
    max-height: 10rem;
  }

  .title {
    overflow: hidden;
  }
}

@media screen and (max-width: 81.25em) {
  .img-container-overlay {
    height: 50rem;
  }
}

@media screen and (max-width: 67.5em) {
  .box {
    min-width: 35vw;
    max-width: 35vw;
  }
}

@media screen and (max-width: 54.75em) {
  .img-container {
    height: 21rem;
  }

  .flag img {
    width: 10rem;
  }
}

@media screen and (max-width: 48em) {
  .col-auto {
    margin-right: auto;
    margin-left: auto;
  }

  .box {
    padding: 3rem;
    min-width: 35rem;
    max-width: 100%;
    width: 100%;
  }

  .flag img {
    width: 14rem;
  }

  .img-container {
    height: 34rem;
  }
}

@media screen and (max-width: 36em) {
  .col-box {
    margin-bottom: 2rem;
  }

  .box {
    padding: 2rem;
    min-height: auto;
    margin-bottom: 2rem;
  }

  .flag img {
    width: 8rem;
  }

  .img-container {
    height: 20rem;
  }

  .img-container-overlay,
  .overlay-content-wrapper {
    min-width: 30rem;
  }
}

@media screen and (max-width: 31.25em) {
  .title-container {
    margin: 0;
  }
}

@media screen and (max-width: 28.125em) {
  .box {
    min-width: 20rem;
  }
}

@media screen and (max-width: 20em) {
  .box {
    padding: 2rem;
    min-height: auto;
    margin-bottom: 2rem;
  }

  .flag img {
    width: 8rem;
  }

  .img-container {
    height: 17rem;
  }
}

@media screen and (max-width: 999em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 5rem;
  }
}

@media screen and (max-width: 112.6875em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 4rem;
  }
}

@media screen and (max-width: 93.9375em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 3rem;
  }
}

@media screen and (max-width: 48em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 4rem;
  }
}

@media screen and (max-width: 36em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 3rem;
  }
}

@media screen and (max-width: 20em) {
  #ctMerchGallery .title,
  .price-wrapper div {
    font-size: 2.5rem;
  }
}
</style>
