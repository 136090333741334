<template>
  <main v-if="homepage && homepage.data" :key="homepage">
    <LandingHeroSection
      :landingText="homepage.data.attributes.landingText"
      :landingCarousel="homepage.data.attributes.landingCarousel"
      :logo="homepage.data.attributes.logo"
      :navData="navigation"
    />
    <div v-for="(body, index) in homepage.data.attributes.body" :key="body.id">
      <div v-if="body.__typename === 'ComponentContentTypesCtBoxes'">
        <ContentTypeBoxes :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtWideBoxes'">
        <ContentTypeWideBoxes :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtImage'">
        <ContentTypeImage :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtTextWImage'">
        <ContentTypeTextWImage :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtText'">
        <ContentTypeText :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtCallToAction'">
        <ContentTypeCallToAction :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtFeedback'">
        <ContentTypeFeedback :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtDownload'">
        <ContentTypeDownload :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtPerson'">
        <ContentTypePerson :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtExtendedCards'">
        <ContentTypeExtendedCards :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtImageGallery'">
        <ContentTypeImageGallery :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtMerchGallery'">
        <ContentTypeMerchGallery :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtContact'">
        <ContentTypeContact :body="body" :pos="index" />
      </div>
      <div v-if="body.__typename === 'ComponentContentTypesCtHeroSection'">
        <ContentTypeHeroSection :body="body" :pos="index" />
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import ContentTypeBoxes from "../components/ContentTypeBoxes.vue";
import ContentTypeWideBoxes from "../components/ContentTypeWideBoxes.vue";
import ContentTypeImage from "../components/ContentTypeImage.vue";
import ContentTypeTextWImage from "../components/ContentTypeTextWImage.vue";
import ContentTypeText from "../components/ContentTypeText.vue";
import ContentTypeCallToAction from "../components/ContentTypeCallToAction.vue";
import ContentTypeFeedback from "../components/ContentTypeFeedback.vue";
import ContentTypeDownload from "../components/ContentTypeDownload.vue";
import ContentTypePerson from "../components/ContentTypePerson.vue";
import ContentTypeExtendedCards from "../components/ContentTypeExtendedCards.vue";
import ContentTypeImageGallery from "../components/ContentTypeImageGallery.vue";
import ContentTypeMerchGallery from "../components/ContentTypeMerchGallery.vue";
import ContentTypeContact from "../components/ContentTypeContact.vue";
import ContentTypeHeroSection from "../components/ContentTypeHeroSection.vue";
import Footer from "../components/Footer.vue";
import LandingHeroSection from "../components/LandingHeroSection.vue";

export default {
  name: "Home",
  components: {
    ContentTypeBoxes,
    ContentTypeWideBoxes,
    ContentTypeImage,
    ContentTypeTextWImage,
    ContentTypeText,
    ContentTypeCallToAction,
    ContentTypeFeedback,
    ContentTypeDownload,
    ContentTypePerson,
    ContentTypeExtendedCards,
    ContentTypeImageGallery,
    ContentTypeMerchGallery,
    ContentTypeContact,
    ContentTypeHeroSection,
    Footer,
    LandingHeroSection,
  },
  data() {
    return {
      homepage: {},
      navigation: {},
    };
  },
  computed: {
    indexing() {
      if (!this.homepage.data.attributes.Seo.indexing) {
        return "noindex";
      }
      return "index";
    },
  },
  metaInfo() {
    if (
      this.homepage &&
      typeof this.homepage.data !== "undefined" &&
      typeof this.homepage.data.attributes.title !== "undefined"
    ) {
      return {
        title: `${this.homepage.data.attributes.title} - SuchtWerk Tattoo`,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.homepage.data.attributes.Seo.description,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: this.homepage.data.attributes.Seo.keywords,
          },
          {
            vmid: "robots",
            name: "robots",
            content: this.indexing,
          },
        ],
      };
    } else {
      return { title: "SuchtWerk Tattoo" };
    }
  },
  apollo: {
    homepage: {
      query: require("../graphql/Home.gql"),
      result(result) {
        this.homepage = result.data.homepage;
        this.navigation = result.data.navigation.data.attributes;
      },
    },
  },
};
</script>
