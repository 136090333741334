<template>
  <ApolloQuery :query="require('../graphql/Footer.gql')">
    <template
      v-slot="{
        result: {
          data: {
            footer: {
              data: { attributes },
            },
          },
        },
      }"
    >
      <footer v-if="attributes" class="bg-gray container">
        <div class="row justify-content-between">
          <div class="col d-flex col-address col-md-auto">
            <div class="wrapper">
              <h3 class="text-turquoiseGray mb-3">Adresse</h3>
              <div class="text address">
                <p class="text-white">{{ attributes.address.name }}</p>
                <p class="text-white">{{ attributes.address.street }}</p>
                <p class="text-white">
                  {{ attributes.address.plz }} {{ attributes.address.city }}
                </p>
              </div>
              <div id="contact-data-mobile">
                <div
                  class="d-flex justify-content-between"
                  v-for="contact in attributes.contact"
                  :key="contact.id"
                >
                  <p class="text-white">{{ contact.description }}:&nbsp;</p>
                  <p class="text-white ms-5">{{ contact.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-flex col-openings col-md-auto">
            <div class="wrapper opening-wrapper">
              <h3 class="text-turquoiseGray mb-3">Öffnungszeiten</h3>
              <div
                class="d-flex justify-content-between w-75"
                v-for="time in attributes.openings"
                :key="time.id"
              >
                <p class="text-white">{{ time.description }}&nbsp;</p>
                <p class="text-white">{{ time.content }}</p>
              </div>
              <p class="text-white mt-5">{{ attributes.infoText }}</p>
            </div>
          </div>
          <div class="col d-flex col-contact col-md-auto" id="contact-col">
            <div class="wrapper wrapper-contact">
              <h3 class="text-turquoiseGray mb-3">Kontakt</h3>
              <div
                class="d-flex justify-content-between"
                v-for="contact in attributes.contact"
                :key="contact.id"
              >
                <p class="text-white">{{ contact.description }}:&nbsp;</p>
                <p class="text-white ms-5">{{ contact.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <FooterSmall :legalLinks="attributes.legalLinks" />
    </template>
  </ApolloQuery>
</template>

<script>
import FooterSmall from "./FooterSmall.vue";
export default {
  name: "Footer",
  components: { FooterSmall },
};
</script>

<style scoped>
footer * {
  word-break: keep-all !important;
}
.wrapper-contact {
  width: -moz-fit-content;
  width: fit-content;
}

#contact-data-mobile {
  display: none;
}

.opening-wrapper {
  width: min-content;
  margin: 0 5rem;
}

.col-openings {
  justify-content: center;
}

.col-contact {
  justify-content: end;
  justify-content: flex-end;
}

.wrapper {
  min-width: 31rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 88.75em) {
  #contact-col {
    display: none !important;
  }

  #contact-data-mobile {
    display: block;
    margin-top: 2rem;
    width: fit-content;
  }
}

@media screen and (max-width: 61.8125em) {
  .opening-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .col-contact,
  .col-openings {
    justify-content: start;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 26.25em) {
  .opening-wrapper {
    width: -moz-fit-content;
    width: fit-content;
    min-width: 20rem;
  }

  .wrapper-contact {
    min-width: 20rem;
  }
}
</style>
