<template>
  <footer class="bg-gray container-x">
    <div class="row justify-content-between" id="desktop">
      <div class="text-white copyright col-auto">
        &copy; SuchtWerk Tattoo Studio {{ new Date().getFullYear() }}
      </div>
      <div class="col-auto text-center">
        <a href="https://strawbinary.de/" target="_blank" class="mx-5"
          ><img
            height="30"
            src="/assets/graphics/MADE_BY_STRAWBINARY.svg"
            alt="Made by Strawbinary Logo"
          />
        </a>
      </div>
      <div class="col-auto d-flex justify-content-between">
        <Button
          class="mx-3"
          v-for="link in legalLinks"
          :key="link.id"
          :button="link"
        />
      </div>
    </div>
    <div class="row justify-content-between" id="mobile">
      <div class="col-auto d-flex justify-content-center pb-4">
        <div class="row">
          <Button
            class="my-3 col"
            v-for="link in legalLinks"
            :key="link.id"
            :button="link"
          />
        </div>
      </div>
      <div class="text-white text-center mb-5 copyright col-auto">
        &copy; SuchtWerk Tattoo Studio {{ new Date().getFullYear() }}
      </div>
      <div class="col-auto text-center pb-5">
        <a href="https://strawbinary.de/" target="_blank"
          ><img
            height="30"
            src="/assets/graphics/MADE_BY_STRAWBINARY.svg"
            alt="Made by Strawbinary Logo"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Button from "./element/Button.vue";
export default {
  name: "FooterSmall",
  components: {
    Button,
  },
  props: {
    legalLinks: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.copyright {
  font-size: 2.4rem;
}

#mobile {
  display: none;
}

#desktop {
  padding: 2rem 0;
}

@media screen and (max-width: 81.25em) {
  #mobile {
    display: block;
    padding-top: 1rem;
  }
  #desktop {
    display: none;
  }

  #mobile button {
    margin: 0;
  }
}
</style>
