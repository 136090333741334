<template>
  <main>
    <div v-if="pages.data">
      <div
        v-for="(body, index) in pages.data[0].attributes.body"
        :key="body.id"
      >
        <div v-if="body.__typename === 'ComponentContentTypesCtBoxes'">
          <ContentTypeBoxes :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtWideBoxes'">
          <ContentTypeWideBoxes :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtImage'">
          <ContentTypeImage :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtTextWImage'">
          <ContentTypeTextWImage :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtText'">
          <ContentTypeText :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtCallToAction'">
          <ContentTypeCallToAction :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtFeedback'">
          <ContentTypeFeedback :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtDownload'">
          <ContentTypeDownload :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtPerson'">
          <ContentTypePerson :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtExtendedCards'">
          <ContentTypeExtendedCards :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtImageGallery'">
          <ContentTypeImageGallery :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtContact'">
          <ContentTypeContact :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtMerchGallery'">
          <ContentTypeMerchGallery :body="body" :pos="index" />
        </div>
        <div v-if="body.__typename === 'ComponentContentTypesCtHeroSection'">
          <ContentTypeHeroSection :body="body" :pos="index" />
        </div>
      </div>
    </div>
    <Footer />
  </main>
</template>

<script>
import ContentTypeBoxes from "../components/ContentTypeBoxes.vue";
import ContentTypeWideBoxes from "../components/ContentTypeWideBoxes.vue";
import ContentTypeImage from "../components/ContentTypeImage.vue";
import ContentTypeTextWImage from "../components/ContentTypeTextWImage.vue";
import ContentTypeText from "../components/ContentTypeText.vue";
import ContentTypeCallToAction from "../components/ContentTypeCallToAction.vue";
import ContentTypeFeedback from "../components/ContentTypeFeedback.vue";
import ContentTypeDownload from "../components/ContentTypeDownload.vue";
import ContentTypePerson from "../components/ContentTypePerson.vue";
import ContentTypeExtendedCards from "../components/ContentTypeExtendedCards.vue";
import ContentTypeImageGallery from "../components/ContentTypeImageGallery.vue";
import ContentTypeMerchGallery from "../components/ContentTypeMerchGallery.vue";
import ContentTypeContact from "../components/ContentTypeContact.vue";
import ContentTypeHeroSection from "../components/ContentTypeHeroSection.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Page",
  data() {
    return {
      slug: this.$route.params.slug,
      pages: [],
    };
  },
  computed: {
    indexing() {
      if (!this.pages.data[0].attributes.Seo.indexing) {
        return "noindex";
      }
      return "index";
    },
  },
  metaInfo() {
    if (
      typeof this.pages.data !== "undefined" &&
      typeof this.pages.data[0].attributes.title !== "undefined"
    ) {
      return {
        title: `${this.pages.data[0].attributes.title} - SuchtWerk Tattoo`,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.pages.data[0].attributes.Seo.description,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: this.pages.data[0].attributes.Seo.keywords,
          },
          {
            vmid: "robots",
            name: "robots",
            content: this.indexing,
          },
        ],
      };
    } else {
      return { title: "SuchtWerk Tattoo" };
    }
  },
  components: {
    ContentTypeBoxes,
    ContentTypeWideBoxes,
    ContentTypeImage,
    ContentTypeTextWImage,
    ContentTypeText,
    ContentTypeCallToAction,
    ContentTypeFeedback,
    ContentTypeDownload,
    ContentTypePerson,
    ContentTypeExtendedCards,
    ContentTypeImageGallery,
    ContentTypeMerchGallery,
    ContentTypeContact,
    ContentTypeHeroSection,
    Footer,
  },
  apollo: {
    pages: {
      query: require("../graphql/Page.gql"),
      variables() {
        return {
          slug: { eq: this.slug },
        };
      },
      result({ data }) {
        if (!data.pages.data.length) {
          this.$router.push({ path: "/" });
        }
      },
    },
  },
};
</script>

<style lang="css" scoped></style>
