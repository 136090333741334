<template>
  <section id="ctContact" class="bg-darkGray">
    <div class="container">
      <div class="contact-wrapper">
        <div class="row">
          <div class="col col-md-6 col-sm-6 map">
            <img src="/assets/graphics/contact-map.svg" alt="Map" />
          </div>
          <div class="col col-md-6 col-sm-6">
            <div class="outer-box">
              <h3 class="title">Adresse</h3>
              <div class="text address">
                <p>{{ body.name }}</p>
                <p>{{ body.street }}</p>
                <p>{{ body.city }}</p>
              </div>
              <div class="text inner-box">
                <p class="description">E-Mail</p>
                <p class="content">{{ body.email }}</p>
                <p class="description">Telefon</p>
                <p class="content">{{ body.telephone }}</p>
              </div>
              <h3 class="title openinghours">Öffnungszeiten</h3>
              <div
                class="text inner-box"
                v-for="time in body.time"
                :key="time.id"
              >
                <p class="description">{{ time.description }}</p>
                <p class="content">{{ time.content }}</p>
              </div>
              <div class="text agreement" v-if="body.agreement">
                <p>... und nach Vereinbarung!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContentTypeContact",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.contact-wrapper {
  width: 100%;
  height: 100%;
}

.map img {
  width: auto;
  height: 100%;
}

.outer-box {
  margin-left: 25rem;
}

.title {
  margin-bottom: 1.5rem;
}

.address {
  margin-bottom: 1.5rem;
}

.inner-box {
  width: 100%;
}

.inner-box p {
  display: inline-block;
}

.description {
  width: 35%;
}

.content {
  width: 65%;
  vertical-align: top;
}

.openinghours {
  margin-top: 5rem;
}

.agreement {
  margin-top: 1.5rem;
}

@media screen and (max-width: 999em) {
  .map img {
    width: 68rem;
    height: 68rem;
  }
}

@media screen and (max-width: 121.6875em) {
  .outer-box {
    margin-left: 15rem;
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 105.25em) {
  .map img {
    width: 60rem;
    height: 60rem;
  }

  .outer-box {
    margin-left: 10rem;
  }
}

@media screen and (max-width: 91.25em) {
  .map img {
    width: 46rem;
    height: 46rem;
  }

  .outer-box {
    margin-left: 5rem;
  }

  .openinghours {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 79.125em) {
  .col {
    width: 100%;
    min-width: 100%;
    text-align: center;
  }

  .map {
    display: unset;
    align-items: unset;
    justify-content: unset;
  }

  .map img {
    width: 100%;
    height: auto;
    margin-bottom: 5rem;
  }

  .outer-box {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .title {
    text-align: left;
  }

  .text {
    text-align: left;
  }
}

@media screen and (max-width: 48em) {
  .map img {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 20em) {
  .map img {
    margin-bottom: 2rem;
  }
}
</style>
