<template>
  <section id="ctImageGallery" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h3>{{ body.headline }}</h3>
      </div>
      <div id="galleryWrapper">
        <div class="row justify-content-between">
          <div
            class="col-auto"
            v-for="(imageGallery, index) in body.imageGallery.data"
            :key="imageGallery.data"
          >
            <div class="box-wrapper">
              <div class="box">
                <img
                  id="galleryImg"
                  @click="openOverlay(index)"
                  :src="url + imageGallery.attributes.url"
                  :alt="imageGallery.attributes.alternativeText"
                />
                <img
                  class="overlay"
                  @click="openOverlay()"
                  src="/assets/graphics/image-gallery-lens.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="gallerySlider" v-if="overlayOpen" class="background">
        <button @click="openOverlay()" id="crossBtn"></button>
        <button
          @click="swapPicture('left')"
          id="arrowLEFT"
          class="arrowImg arrow"
        ></button>
        <Carousel
          :speed="0"
          :navigateTo="page"
          :perPage="1"
          :paginationEnabled="false"
        >
          <Slide
            v-for="(imageGallery, index) in body.imageGallery.data"
            :key="imageGallery.data"
          >
            <div class="centerBox">
              <div class="containerImg">
                <div>
                  <img
                    :src="url + imageGallery.attributes.url"
                    :alt="imageGallery.attributes.alternativeText"
                  />
                  <div class="pageText">
                    {{ index + 1 }} von {{ body.imageGallery.data.length }}
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
        <button
          @click="swapPicture('right')"
          id="arrowRIGHT"
          class="arrowImg arrow"
        ></button>
      </div>
      <div id="galleryMobile">
        <Carousel
          :speed="0"
          :navigateTo="page"
          :perPage="1"
          :paginationEnabled="false"
        >
          <Slide
            v-for="(imageGallery, index) in body.imageGallery.data"
            :key="imageGallery.data"
          >
            <div class="centerBox">
              <div class="containerImg">
                <div>
                  <img
                    :src="url + imageGallery.attributes.url"
                    :alt="imageGallery.attributes.alternativeText"
                  />

                  <div class="pageText">
                    {{ index + 1 }} von {{ body.imageGallery.data.length }}
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
        <div class="btnMobile">
          <button
            @click="swapPicture('left')"
            id="arrowLEFT"
            class="arrowImg arrow"
          ></button>
          <button
            @click="swapPicture('right')"
            id="arrowRIGHT"
            class="arrowImg arrow"
          ></button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { getStrapiUrl } from '../helper';

export default {
  name: 'ContentTypeImageGallery',
  data() {
    return {
      page: 0,
      overlayOpen: false,
      url: getStrapiUrl(),
      upDown: '',
      index: 1,
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    openOverlay: function (index) {
      this.page = index;
      if (this.overlayOpen) {
        this.overlayOpen = false;
        document.documentElement.style.overflow = 'auto';
      } else {
        this.overlayOpen = true;
        document.documentElement.style.overflow = 'hidden';
      }
    },
    swapPicture: function (upDown) {
      if (upDown === 'right') {
        this.page += 1;
        if (this.page === this.body.imageGallery.data.length) {
          this.page = 0;
        }
      } else {
        this.page -= 1;
        if (this.page < 0) {
          this.page = this.body.imageGallery.data.length - 1;
        }
      }
    },
  },
};
</script>

<style scoped>
h3 {
  text-align: left;
}

#galleryWrapper {
  text-align: center;
}

.box-wrapper {
  margin: 1rem 2rem 7.5rem 0;
}

.box {
  display: block;
  position: relative;
  width: 44rem;
  height: 44rem;
}

#galleryImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  width: 40%;
  height: auto;
}

.box:hover .overlay {
  opacity: 1;
}

.pageText {
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  color: var(--tattoo-white);
  text-align: center;
}

.centerBox {
  text-align: center;
  vertical-align: middle;
  height: 100vh;
}

.containerImg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#crossBtn {
  background: url(/assets/graphics/image-gallery-cross.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 5rem 5rem;
  background-position: center;
  border: none;
  padding: 2rem 2rem;
  text-decoration: none;
  display: inline-block;
  position: fixed;
  top: 2%;
  right: 10%;
  z-index: 999;
  width: 6rem;
  height: 6rem;
}

.arrowImg {
  background: url(/assets/graphics/image-gallery-arrow.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 2rem 2rem;
  background-position: center;
}

.arrow {
  border: none;
  padding: 2rem 2rem;
  text-decoration: none;
  display: inline-block;
  position: fixed;
  top: 50%;
  z-index: 999;
}

#arrowLEFT {
  transform: rotate(180deg);
  left: 10%;
}

#arrowRIGHT {
  right: 10%;
}

#arrowLEFT:hover,
#arrowRIGHT:hover {
  background-color: var(--tattoo-light-turquoise);
}

#gallerySlider {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.background {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
}

section {
  width: 100%;
  height: 100%;
}

#galleryMobile {
  display: none;
}

@media screen and (max-width: 999em) {
  .containerImg img {
    height: 90vh;
    width: 50vw;
  }
}

@media screen and (max-width: 183.375em) {
  .box {
    width: 40rem;
    height: 40rem;
  }
}

@media screen and (max-width: 175em) {
  .box {
    width: 60rem;
    height: 60rem;
  }
}

@media screen and (max-width: 160em) {
  .box {
    width: 55rem;
    height: 55rem;
  }
}

@media screen and (max-width: 144.3125em) {
  .box {
    width: 44rem;
    height: 44rem;
  }
}

@media screen and (max-width: 118.75em) {
  .containerImg img {
    height: 75vh;
    width: 70vw;
  }

  #arrowLEFT {
    left: 6%;
  }

  #arrowRIGHT {
    right: 6%;
  }
}

@media screen and (max-width: 87.5em) {
  .pageText {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 84.875em) {
  #galleryWrapper,
  #gallerySlider {
    display: none;
  }

  .containerImg img {
    height: 50vw;
    width: 50vw;
  }

  #galleryMobile {
    display: block;
    position: relative;
  }

  h3 {
    left: 26%;
    margin-bottom: 2rem;
  }

  .centerBox {
    align-items: flex-start;
  }

  #arrowLEFT {
    position: unset;
  }

  #arrowRIGHT {
    position: unset;
  }

  .pageText {
    font-size: 3rem;
    margin-top: 2rem;
    text-align: center;
  }

  .btnMobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 23%;
  }

  .centerBox {
    height: unset;
  }
}

@media screen and (max-width: 57.625em) {
  .btnMobile {
    padding: 0 14rem;
  }
}

@media screen and (max-width: 48em) {
  .btnMobile {
    padding: 0 20%;
  }

  .pageText {
    font-size: 2rem;
  }

  .arrow {
    padding: 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 36em) {
  .pageText {
    font-size: 2rem;
  }

  .btnMobile {
    padding: 0 5%;
  }

  .containerImg img {
    height: 70vw;
    width: 70vw;
  }
}

@media screen and (max-width: 20em) {
  h3 {
    margin-bottom: 1rem;
  }

  .containerImg img {
    height: 93vw;
    width: 93vw;
  }

  .arrowImg {
    background-size: 1rem 1rem;
  }

  .arrow {
    padding: 1.5rem 1.5rem;
  }

  .btnMobile {
    padding: 0 3%;
  }
}
</style>
