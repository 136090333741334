<template>
  <section id="ctImage" class="bg-darkGray">
    <div class="container">
      <article v-if="body.content" class="content">
        <vue-markdown :source="body.content"></vue-markdown>
      </article>
      <img
        v-if="body.image"
        class="image"
        :src="url + body.image.data.attributes.url"
        :alt="body.image.data.attributes.alternativeText"
      />
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";

export default {
  name: "ContentTypeImage",
  data() {
    return {
      url: getStrapiUrl(),
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.image {
  margin-top: 2rem;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 36em) {
  .image {
    height: 60vw;
    object-fit: cover;
    object-position: center;
  }
}
</style>
