<template>
  <section id="ctPerson" class="bg-darkGray">
    <div class="container">
      <div v-for="person in body.person" :key="person.id">
        <div class="box-container">
          <div class="box">
            <img
              v-if="person.imagePerson"
              :src="url + person.imagePerson.data.attributes.url"
              :alt="person.imagePerson.data.attributes.alternativeText"
            />
          </div>
          <article v-if="person.content" class="content">
            <vue-markdown :source="person.content"></vue-markdown>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";

export default {
  name: "ContentTypePerson",
  data() {
    return {
      url: getStrapiUrl(),
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.box-container {
  width: 100%;
}

.box {
  display: inline-block;
  width: 25vw;
  height: 25vw;
  background-color: var(--tattoo-gray);
  padding: 2rem;
  margin-bottom: 3rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  width: 60%;
  margin-top: 2rem;
  text-align: left;
  float: right;
}

@media screen and (max-width: 48em) {
  .box-container {
    text-align: center;
  }

  .box {
    width: 70vw;
    height: 70vw;
  }

  .content {
    float: unset;
    width: 100%;
    margin-top: unset;
  }
}

@media screen and (max-width: 20em) {
  .box {
    padding: 1rem;
  }
}
</style>
