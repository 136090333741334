<template>
  <section id="CtExtendedCards" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h3>{{ body.headline }}</h3>
      </div>
      <div class="row">
        <div v-for="dropdown in body.dropdown" :key="dropdown.id">
          <Dropdown :dropdown="dropdown" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Dropdown from "./element/Dropdown.vue";
export default {
  name: "ContentTypeExtendedCards",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    Dropdown,
  },
};
</script>

<style scoped></style>
