<template>
  <section id="ctDownload" class="bg-gray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <div class="row">
        <div
          class="content boxes sw-border-left"
          v-for="download in body.download"
          :key="download.id"
        >
          <a :href="url + download.file.data.attributes.url" target="_blank">
            <div class="img-container">
              <svg
                v-if="clicked !== download.id"
                class="image"
                width="22"
                height="29"
                viewBox="0 0 22 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.8002 7.75001V0.100006H1.5502C0.80207 0.100006 0.200195 0.701881 0.200195 1.45001V27.55C0.200195 28.2981 0.80207 28.9 1.5502 28.9H20.4502C21.1983 28.9 21.8002 28.2981 21.8002 27.55V9.10001H14.1502C13.4077 9.10001 12.8002 8.49251 12.8002 7.75001ZM17.1005 19.639L11.6769 25.0221C11.3028 25.3939 10.6987 25.3939 10.3246 25.0221L4.90101 19.639C4.33007 19.0726 4.73057 18.1 5.53382 18.1H9.20019V13.6C9.20019 13.1028 9.60294 12.7 10.1002 12.7H11.9002C12.3974 12.7 12.8002 13.1028 12.8002 13.6V18.1H16.4666C17.2698 18.1 17.6703 19.0726 17.1005 19.639ZM21.4064 6.00626L15.8996 0.493756C15.6464 0.240631 15.3033 0.100006 14.9433 0.100006H14.6002V7.30001H21.8002V6.95688C21.8002 6.60251 21.6596 6.25938 21.4064 6.00626Z"
                  fill="white"
                />
              </svg>
              <lord-icon
                v-else
                delay="1000"
                target="a"
                trigger="loop"
                src="/assets/graphics/checked-icon.json"
              ></lord-icon>
            </div>
            <div class="text-container">
              {{ download.filename }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getStrapiUrl } from "../helper";

export default {
  name: "ContentTypeDownload",
  data() {
    return {
      url: getStrapiUrl(),
      clicked: "",
      hover: "",
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.boxes {
  display: flex;
  background-color: var(--tattoo-dark-gray);
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  position: relative;
}

.img-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.text-container {
  margin-left: 4rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.boxes:hover,
a:hover {
  color: var(--tattoo-light-turquoise);
  text-decoration: none;
  cursor: pointer;
}

.boxes:hover .image path {
  fill: var(--tattoo-light-turquoise);
}

.content {
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  color: var(--tattoo-white);
}

@media screen and (max-width: 20em) {
  .boxes {
    width: 92vw;
    margin-left: 1rem;
  }
}
</style>
