import { render, staticRenderFns } from "./ContentTypeWideBoxes.vue?vue&type=template&id=7aa1ebbe&scoped=true&"
import script from "./ContentTypeWideBoxes.vue?vue&type=script&lang=js&"
export * from "./ContentTypeWideBoxes.vue?vue&type=script&lang=js&"
import style0 from "./ContentTypeWideBoxes.vue?vue&type=style&index=0&id=7aa1ebbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa1ebbe",
  null
  
)

export default component.exports