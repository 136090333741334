<template lang="html">
  <main>
    <button v-on:click="_dropdown" class="btn-style sw-border-left">
      <img
        class="icon"
        :class="[isOpen ? 'rotateUP' : 'rotateDOWN']"
        src="/assets/graphics/dropdown-arrow-down.svg"
        alt="Arrow"
        :key="isOpen"
      />
      <p>
        {{ dropdown.headline }}
      </p>
    </button>
    <transition name="slide">
      <div
        v-if="isOpen"
        class="dropdown-style"
        v-bind:class="{ close: !isOpen }"
      >
        <vue-markdown
          class="markdown bodyBox"
          :source="dropdown.content"
        ></vue-markdown>
      </div>
    </transition>
  </main>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
  name: "Dropdown",
  props: {
    dropdown: {
      type: Object,
    },
  },
  components: {
    VueMarkdown,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    _dropdown: function () {
      if (!this.isOpen) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.btn-style {
  background-color: var(--tattoo-gray);
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: none;
  padding: 1rem;
  margin-top: 2rem;
}

.dropdown-style {
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}

.close {
  display: none;
}

.icon {
  margin-right: 2.5rem;
  margin-left: 1rem;
}

.rotateUP {
  animation: rotationUP 0.3s linear;
  animation-fill-mode: forwards;
}

.rotateDOWN {
  animation: rotationDOWN 0.3s linear;
  animation-fill-mode: forwards;
}

@keyframes rotationUP {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotationDOWN {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.bodyBox {
  background-color: var(--tattoo-darker-gray);
  padding: 2rem;
  padding-left: 7.1rem;
  padding-right: 7.1rem;
  text-align: justify;
}

p {
  font-family: "Universal Serif", "sans-serif" !important;
  font-style: normal !important;
  color: var(--tattoo-white) !important;
}

@media screen and (max-width: 36em) {
  .bodyBox {
    padding-right: 1rem;
    padding-left: 5rem;
  }

  .icon {
  margin-right: 1rem;
  margin-left: 0rem;
}
}
</style>
