<template lang="html">
  <div class="btn-container">
    <h4 class="linkHead" v-if="button.linkHeadline">
      {{ button.linkHeadline }}
    </h4>
    <button type="button" class="button">
      <router-link
        :class="{
          'btn-primary-sw': button.style == 'primary',
          'btn-secondary-sw': button.style == 'secondary',
          'btn-link-sw': button.style == 'link',
          'btn-text-sw': button.style == 'text',
          'btn-dark-primary-sw': button.style == 'dark_primary',
          'btn-blue-icon-sw': button.style == 'blue_icon',
          'btn-nav-link': button.style == 'nav_link',
        }"
        v-if="
          button.page.data &&
          button.page.data.attributes !== null &&
          (button.url == '' || button.url == null)
        "
        :to="{ path: '/' + button.page.data.attributes.slug }"
      >
        <label>
          {{ button.label }}
        </label>
        <img
          class="icon"
          v-if="
            button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
          "
          :src="url + button.icon.data.attributes.url"
          :alt="button.icon.data.attributes.alternativeText"
        />
        <lord-icon
          v-if="
            button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
          "
          target="a"
          trigger="hover"
          :src="url + button.icon.data.attributes.url"
        ></lord-icon>
      </router-link>
      <a
        v-if="
          button.url !== null &&
          button.url !== '' &&
          (button.page === null || button.page.data === null)
        "
        :class="{
          'btn-primary-sw rippel': button.style == 'primary',
          'btn-secondary-sw': button.style == 'secondary',
          'btn-link-sw': button.style == 'link',
          'btn-text-sw': button.style == 'text',
          'btn-dark-primary-sw': button.style == 'dark_primary',
          'btn-blue-icon-sw': button.style == 'blue_icon',
          'btn-nav-link': button.style == 'nav_link',
        }"
        :href="button.url"
      >
        <label>
          {{ button.label }}
        </label>
        <img
          class="icon"
          v-if="
            button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
          "
          :src="url + button.icon.data.attributes.url"
          :alt="button.icon.data.attributes.alternativeText"
        />
        <lord-icon
          v-if="
            button.icon &&
            button.icon.data &&
            button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
          "
          target="a"
          trigger="hover"
          :src="url + button.icon.data.attributes.url"
        ></lord-icon>
      </a>
    </button>
  </div>
</template>

<script>
import { getStrapiUrl } from "../../helper";
export default {
  name: "Button",
  data() {
    return {
      url: getStrapiUrl(),
    };
  },
  props: {
    button: {
      type: Object,
    },
  },
};
</script>

<style lang="css" scoped>
.btn-container {
  position: relative;
}

.linkHead {
  text-align: center;
  margin-bottom: 3rem;
  min-width: 20rem;
}

lord-icon {
  height: 5rem;
  width: 5rem;
  margin-bottom: 0.6rem;
  margin-left: 0.6rem;
  margin-right: -1.6rem;
}

.icon {
  height: 4rem;
  width: 4rem;
  margin-bottom: 0.6rem;
  margin-left: 1.6rem;
  margin-right: -1rem;
}

@media screen and (max-width: 93.75em) {
  lord-icon {
    height: 4rem;
    width: 4rem;
    margin-right: -0.6rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 34.375em) {
  .btn-container {
    margin-bottom: 2rem;
    width: 100%;
  }
}
</style>
