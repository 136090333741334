<template>
  <section id="ctWideBoxes" class="bg-gray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <div>
        <div v-for="widebox in body.widebox" :key="widebox.id">
          <div v-if="widebox.content" class="boxes sw-border-left">
            <vue-markdown :source="widebox.content"></vue-markdown>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "ContentTypeWideBoxes",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.bg-dark-gray {
  padding: 1rem;
}

.headline {
  text-align: left;
}

h4 {
  margin-bottom: 1rem;
}

.boxes {
  text-align: left;
  background-color: var(--tattoo-dark-gray);
  margin-bottom: 3rem;
  padding: 2rem;
  min-width: 25rem;
  color: var(--tattoo-turquoise-gray);
}
</style>
