import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import VueMeta from "vue-meta";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

defineLordIconElement(loadAnimation);

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.use(VueApollo);

/* CSS Overwritings */
import "./scss/overwritings.scss";

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
