<template>
  <section id="ctFeedback" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <Carousel
        :perPageCustom="[
          [0, 1],
          [1024, 2],
          [1200, 3],
        ]"
        @page-change="pageChanged"
        :navigateTo="page"
        :paginationEnabled="false"
      >
        <Slide v-for="(feedback, index) in body.feedback" :key="feedback.id">
          <div v-show="feedback.rating >= 0" class="boxes">
            <div class="star-wrapper">
              <img
                v-for="index in stars(feedback.rating)"
                :key="index"
                src="/assets/graphics/colored-star.svg"
                alt="Colored Star"
              />
              <img
                src="/assets/graphics/half-colored-star.svg"
                alt="Half Colored Star"
                v-if="isDecimal(feedback.rating)"
              />
              <img
                v-for="index in notColoredStars(feedback.rating)"
                :key="index"
                src="/assets/graphics/star.svg"
                alt="Colored Star"
              />
            </div>
            <div class="text-center text-container">
              <div class="inner-text">
                <vue-markdown v-if="feedback.content"
                  >{{ feedback.content | truncate(90, "...") }}
                </vue-markdown>
                <button
                  v-if="feedback.content.length >= 90"
                  class="btn-link-sw button my-3 mx-auto"
                  @click="
                    openOverlay(
                      feedback.rating,
                      feedback.content,
                      feedback.name
                    )
                  "
                >
                  <label> Mehr lesen </label>
                </button>
                <vue-markdown
                  v-if="feedback.name"
                  :source="feedback.name"
                ></vue-markdown>
              </div>
            </div>
            <span class="marks">,,</span>
          </div>
          <div class="pageText">
            {{ index + 1 }} von {{ body.feedback.length }}
          </div>
        </Slide>
      </Carousel>
      <div class="btnMobile">
        <button
          @click="swapSlide('left')"
          id="arrowLEFT"
          class="arrowImg arrow"
        ></button>
        <button
          @click="swapSlide('right')"
          id="arrowRIGHT"
          class="arrowImg arrow"
        ></button>
      </div>
    </div>
    <Overlay ref="overlay">
      <tempplate>
        <div>
          <div class="star-wrapper">
            <img
              v-for="index in stars(overlayRating)"
              :key="index"
              src="/assets/graphics/colored-star.svg"
              alt="Colored Star"
            />
            <img
              src="/assets/graphics/half-colored-star.svg"
              alt="Half Colored Star"
              v-if="isDecimal(overlayRating)"
            />
            <img
              v-for="index in notColoredStars(overlayRating)"
              :key="index"
              src="/assets/graphics/star.svg"
              alt="Colored Star"
            />
          </div>
          <div class="text-center">
            <div class="">
              <vue-markdown v-if="overlayText">{{ overlayText }} </vue-markdown>
              <br />
              <vue-markdown
                v-if="overlayName"
                :source="overlayName"
              ></vue-markdown>
            </div>
          </div>
          <span class="marks">,,</span>
        </div>
      </tempplate>
    </Overlay>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { Carousel, Slide } from "vue-carousel";
import Overlay from "./element/Overlay.vue";

export default {
  name: "ContentTypeFeedback",
  data() {
    return {
      upDown: "",
      index: 1,
      page: 0,
      overlayRating: 0,
      overlayText: "",
      overlayName: "",
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
    Carousel,
    Slide,
    Overlay,
  },
  methods: {
    // returns true if a number is a decimal number
    isDecimal: function (number) {
      return number % 1 !== 0;
    },
    // return the number of stars for a given rating
    stars: function (rating) {
      return Math.floor(rating);
    },
    // return the number of not colored stars for a given rating
    notColoredStars: function (rating) {
      var notColoredStars = this.isDecimal(rating)
        ? 4 - this.stars(rating)
        : 5 - this.stars(rating);
      if (notColoredStars > 0) {
        return Math.floor(notColoredStars);
      }
      return 0;
    },
    swapSlide: function (upDown) {
      if (upDown === "right") {
        this.page += 1;
        if (this.page === this.body.feedback.length) {
          this.page = 0;
        }
      } else {
        this.page -= 1;
        if (this.page < 0) {
          this.page = this.body.feedback.length - 1;
        }
      }
    },
    pageChanged: function (currentPage) {
      this.page = currentPage;
    },
    openOverlay(rating, content, name) {
      this.overlayRating = rating;
      this.overlayText = content;
      this.overlayName = name;
      this.$refs.overlay.show();
    },
  },
};
</script>

<style scoped>
.boxes {
  background-color: var(--tattoo-gray);
  margin: 5rem 3rem;
  padding: 2rem;
  position: relative;
  color: var(--tattoo-turquoise-gray);
  min-height: 30rem;
}

.text-container {
  position: relative;
  height: 18rem;
}

.inner-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.marks {
  font-size: 15rem;
  font-family: "Universal Serif", sans-serif;
  letter-spacing: -1rem;
  line-height: 1;
  color: var(--tattoo-light-turquoise);
  position: absolute;
  bottom: -2.8rem;
  right: 3rem;
}

.star-wrapper {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2rem auto;
}

.pageText {
  display: none;
}

.btnMobile {
  position: relative;
  bottom: 20rem;
}

.arrowImg {
  background: url(/assets/graphics/image-gallery-arrow.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 2rem 2rem;
  background-position: center;
}

.arrow {
  border: none;
  padding: 2rem 2rem;
  text-decoration: none;
  display: inline-block;
  position: absolute;
}

#arrowLEFT {
  transform: rotate(180deg);
  left: -5%;
}

#arrowRIGHT {
  right: -5%;
}

#arrowLEFT:hover,
#arrowRIGHT:hover {
  background-color: var(--tattoo-light-turquoise);
}

@media screen and (max-width: 63.9375em) {
  .boxes {
    height: 25rem;
  }

  .inner-text {
    position: unset;
    top: unset;
    transform: unset;
  }

  .star-wrapper {
    width: 50%;
  }

  .pageText {
    display: block;
    margin-top: 4rem;
    font-size: 2rem;
    font-family: "Oswald", sans-serif;
    font-style: normal;
    color: var(--tattoo-white);
    text-align: center;
  }

  .btnMobile {
    bottom: 3.5rem;
  }

  #arrowLEFT {
    transform: rotate(180deg);
    left: 10%;
  }

  #arrowRIGHT {
    right: 10%;
  }
}

@media screen and (max-width: 28.125em) {
  .boxes {
    margin: 3rem 0rem;
    height: 27rem;
    max-height: 30rem;
  }

  .star-wrapper {
    width: 75%;
  }

  .star-wrapper img {
    width: 2.5rem;
  }
}
</style>
