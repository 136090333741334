var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/Nav.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
return [(data)?_c('nav',{attrs:{"id":"nav"}},[_c('div',{staticClass:"blur"}),_c('div',{class:{ navOpen: _vm.navOpen },attrs:{"id":"nav-desktop"}},[_c('div',{attrs:{"id":"menuToggle"}},[_c('input',{attrs:{"type":"checkbox"},on:{"click":_vm.navTrigger}}),_c('span'),_c('span'),_c('span')])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scY > 300),expression:"scY > 300"}],staticClass:"left-logo"},[_c('img',{attrs:{"src":"/assets/graphics/Suchtwerk_Logo.svg","alt":"Logo"}})]),_c('div',{staticClass:"sidenav",attrs:{"id":"sidenav"}},[_c('div',{staticClass:"sidenav-bg"},[_c('div',{staticClass:"row h-xl-100"},[_c('div',{staticClass:"col d-flex align-items-xl-center justify-content-xl-center"},[_c('div',{staticClass:"link-container"},[_c('img',{staticClass:"logo medium-size",attrs:{"src":"/assets/graphics/Suchtwerk_Logo.svg","alt":"Logo"}}),_c('Button',{key:_vm.navOpen,staticClass:"nav-link",attrs:{"button":{
                    label: 'Home',
                    url: '/',
                    style: 'nav_link',
                    page: { data: null },
                    icon: null,
                  }},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}}),_vm._l((data.navigation.data.attributes.navLink),function(link){return _c('Button',{key:link.id,staticClass:"nav-link",attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}})}),_c('div',{staticClass:"large-size pb-5"},_vm._l((data.navigation.data.attributes.legalLink),function(link){return _c('Button',{key:link.id,staticClass:"legal-link",attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}})}),1)],2)]),_c('div',{staticClass:"col align-items-center logo-call-to-action-wrapper large-size"},[_c('div',[_c('div',{staticClass:"address-wrapper"},[_c('h3',{staticClass:"text-turquoiseGray mb-3"},[_vm._v("Adresse")]),_c('div',{staticClass:"text address"},[_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.name)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.street)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.plz)+" "+_vm._s(data.navigation.data.attributes.address.city)+" ")])])]),_c('div',{staticClass:"opening-wrapper"},[_c('h3',{staticClass:"text-turquoiseGray mb-3"},[_vm._v("Öffnungszeiten")]),_vm._l((data.navigation.data.attributes.openings),function(time){return _c('div',{key:time.id,staticClass:"d-flex justify-content-between w-75"},[_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(time.description)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(time.content))])])}),_c('p',{staticClass:"text-white mt-5"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.openingInfo)+" ")])],2),_c('div',{staticClass:"call-to-action-wrapper"},[_c('div',{staticClass:"text-center call-to-action d-flex"},_vm._l((data.navigation.data.attributes
                        .callToAction),function(link){return _c('Button',{key:link.id,staticClass:"cta-btn",attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}})}),1)])])]),_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('div',{staticClass:"socialmedia-wrapper position-relative text-center bg-darkGray"},[_c('div',{staticClass:"bg-darkGray socialmedia-wrapper-inner position-relative"},[_c('a',{attrs:{"href":data.navigation.data.attributes.facebook,"target":"_blank"}},[_c('i',{staticClass:"fa fa-brands fa-facebook-f text-white"})]),_c('br'),_c('a',{attrs:{"href":data.navigation.data.attributes.facebook,"target":"_blank"}},[_c('i',{staticClass:"fa fa-brands fa-instagram text-white"})])])])])]),_c('div',{staticClass:"row medium-size"},[_c('div',{staticClass:"col align-items-center logo-call-to-action-wrapper"},[_c('div',[_c('div',{staticClass:"call-to-action-wrapper my-5"},[_c('div',{staticClass:"text-center call-to-action d-flex"},_vm._l((data.navigation.data.attributes
                        .callToAction),function(link){return _c('Button',{key:link.id,staticClass:"cta-btn",attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}})}),1)]),_c('div',{staticClass:"address-wrapper"},[_c('h3',{staticClass:"text-turquoiseGray mb-3"},[_vm._v("Adresse")]),_c('div',{staticClass:"text address"},[_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.name)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.street)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.address.plz)+" "+_vm._s(data.navigation.data.attributes.address.city)+" ")])])]),_c('div',{staticClass:"opening-wrapper"},[_c('h3',{staticClass:"text-turquoiseGray mb-3"},[_vm._v("Öffnungszeiten")]),_vm._l((data.navigation.data.attributes.openings),function(time){return _c('div',{key:time.id,staticClass:"d-flex justify-content-between w-75"},[_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(time.description)+" ")]),_c('p',{staticClass:"text-white"},[_vm._v(_vm._s(time.content))])])}),_c('p',{staticClass:"text-white mt-5"},[_vm._v(" "+_vm._s(data.navigation.data.attributes.openingInfo)+" ")])],2),_c('div',{staticClass:"medium-size justify-content-center"},[_c('div',{staticClass:"d-flex"},_vm._l((data.navigation.data.attributes
                        .legalLink),function(link){return _c('Button',{key:link.id,staticClass:"legal-link text-center",attrs:{"button":link},nativeOn:{"click":function($event){return _vm.navTrigger.apply(null, arguments)}}})}),1)])])])])])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }