<template>
  <section id="ctHeroSection" class="bg-darkGray">
    <div class="inner-container">
      <div class="img-container">
        <div class="gradient"></div>
        <img
          :src="url + body.image.data.attributes.url"
          :alt="body.image.data.attributes.alternativeText"
        />
        <div class="hero-headline">
          <h1 class="headline p-0">
            <span>{{ body.headline }}</span>
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getStrapiUrl } from "../helper";
export default {
  name: "ContentTypeHeroSection",
  data() {
    return {
      url: getStrapiUrl(),
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
};
</script>

<style scoped>
.inner-container {
  position: relative;
}

.img-container {
  width: 100%;
  height: auto;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 80rem;
  max-height: 80rem;
}

.hero-headline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25%;
}

.gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
}

@media screen and (max-width: 100em) {
  .img-container img {
    min-height: 45rem;
    max-height: 45rem;
  }
}

@media screen and (max-width: 36em) {
  .img-container {
    width: 100%;
    height: 20rem;
  }

  .img-container img {
    min-height: 20rem;
    max-height: 20rem;
  }

  .hero-headline {
    bottom: 10%;
  }
}
</style>
