<template lang="html">
  <ApolloQuery :query="require('../graphql/Nav.gql')">
    <template v-slot="{ result: { data } }">
      <nav id="nav" v-if="data">
        <div class="blur"></div>
        <div id="nav-desktop" :class="{ navOpen: navOpen }">
          <div id="menuToggle">
            <input @click="navTrigger" type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="left-logo" v-show="scY > 300">
          <img src="/assets/graphics/Suchtwerk_Logo.svg" alt="Logo" />
        </div>
        <div id="sidenav" class="sidenav">
          <div class="sidenav-bg">
            <div class="row h-xl-100">
              <div
                class="col d-flex align-items-xl-center justify-content-xl-center"
              >
                <div class="link-container">
                  <img
                    src="/assets/graphics/Suchtwerk_Logo.svg"
                    alt="Logo"
                    class="logo medium-size"
                  />
                  <Button
                    v-on:click.native="navTrigger"
                    :button="{
                      label: 'Home',
                      url: '/',
                      style: 'nav_link',
                      page: { data: null },
                      icon: null,
                    }"
                    :key="navOpen"
                    class="nav-link"
                  />
                  <Button
                    v-on:click.native="navTrigger"
                    v-for="link in data.navigation.data.attributes.navLink"
                    :button="link"
                    :key="link.id"
                    class="nav-link"
                  />
                  <div class="large-size pb-5">
                    <Button
                      v-on:click.native="navTrigger"
                      v-for="link in data.navigation.data.attributes.legalLink"
                      :button="link"
                      :key="link.id"
                      class="legal-link"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col align-items-center logo-call-to-action-wrapper large-size"
              >
                <div>
                  <div class="address-wrapper">
                    <h3 class="text-turquoiseGray mb-3">Adresse</h3>
                    <div class="text address">
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.name }}
                      </p>
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.street }}
                      </p>
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.plz }}
                        {{ data.navigation.data.attributes.address.city }}
                      </p>
                    </div>
                  </div>
                  <div class="opening-wrapper">
                    <h3 class="text-turquoiseGray mb-3">Öffnungszeiten</h3>
                    <div
                      class="d-flex justify-content-between w-75"
                      v-for="time in data.navigation.data.attributes.openings"
                      :key="time.id"
                    >
                      <p class="text-white">{{ time.description }}&nbsp;</p>
                      <p class="text-white">{{ time.content }}</p>
                    </div>
                    <p class="text-white mt-5">
                      {{ data.navigation.data.attributes.openingInfo }}
                    </p>
                  </div>
                  <div class="call-to-action-wrapper">
                    <div class="text-center call-to-action d-flex">
                      <Button
                        v-on:click.native="navTrigger"
                        v-for="link in data.navigation.data.attributes
                          .callToAction"
                        :button="link"
                        :key="link.id"
                        class="cta-btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center">
                <div
                  class="socialmedia-wrapper position-relative text-center bg-darkGray"
                >
                  <div
                    class="bg-darkGray socialmedia-wrapper-inner position-relative"
                  >
                    <a
                      :href="data.navigation.data.attributes.facebook"
                      target="_blank"
                      ><i class="fa fa-brands fa-facebook-f text-white"></i
                    ></a>
                    <br />
                    <a
                      :href="data.navigation.data.attributes.facebook"
                      target="_blank"
                      ><i class="fa fa-brands fa-instagram text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row medium-size">
              <div class="col align-items-center logo-call-to-action-wrapper">
                <div>
                  <div class="call-to-action-wrapper my-5">
                    <div class="text-center call-to-action d-flex">
                      <Button
                        v-on:click.native="navTrigger"
                        v-for="link in data.navigation.data.attributes
                          .callToAction"
                        :button="link"
                        :key="link.id"
                        class="cta-btn"
                      />
                    </div>
                  </div>
                  <div class="address-wrapper">
                    <h3 class="text-turquoiseGray mb-3">Adresse</h3>
                    <div class="text address">
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.name }}
                      </p>
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.street }}
                      </p>
                      <p class="text-white">
                        {{ data.navigation.data.attributes.address.plz }}
                        {{ data.navigation.data.attributes.address.city }}
                      </p>
                    </div>
                  </div>
                  <div class="opening-wrapper">
                    <h3 class="text-turquoiseGray mb-3">Öffnungszeiten</h3>
                    <div
                      class="d-flex justify-content-between w-75"
                      v-for="time in data.navigation.data.attributes.openings"
                      :key="time.id"
                    >
                      <p class="text-white">{{ time.description }}&nbsp;</p>
                      <p class="text-white">{{ time.content }}</p>
                    </div>
                    <p class="text-white mt-5">
                      {{ data.navigation.data.attributes.openingInfo }}
                    </p>
                  </div>
                  <div class="medium-size justify-content-center">
                    <div class="d-flex">
                      <Button
                        v-on:click.native="navTrigger"
                        v-for="link in data.navigation.data.attributes
                          .legalLink"
                        :button="link"
                        :key="link.id"
                        class="legal-link text-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </template>
  </ApolloQuery>
</template>

<script>
import Button from "./element/Button.vue";
import { getStrapiUrl } from "../helper";
import $ from "jquery";
export default {
  name: "Nav",
  data() {
    return {
      url: getStrapiUrl(),
      navOpen: false,
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  components: {
    Button,
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    navTrigger: function () {
      if (this.navOpen) {
        this.navOpen = false;
        $("#menuToggle input").prop("checked", false);
        document.getElementById("sidenav").style.left = "100%";
        $("html").css("overflow-y", "auto");
      } else {
        document.getElementById("sidenav").style.left = "0";
        $("#menuToggle input").prop("checked", true);
        this.navOpen = true;
        $("html").css("overflow-y", "hidden");
      }
    },
  },
};
</script>

<style lang="css" scoped>
.blur {
  background: linear-gradient(180deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
  height: 10rem;
  width: 100%;
  position: fixed;
  z-index: 800;
}
.navOpen#nav-desktop {
  background: transparent;
}

.navOpen .logo-wrapper-outer {
  display: none;
}

.left-logo {
  position: fixed;
  z-index: 999;
  top: 2rem;
  left: 7rem;
}

.left-logo img {
  width: 18rem;
  height: auto;
}

.large-size {
  display: flex;
}

.medium-size {
  display: none;
}

#nav-mobile {
  display: none;
  position: fixed;
  z-index: 1998;
  top: 0;
  height: 7rem;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

#nav-desktop {
  position: fixed;
  z-index: 1999;
  right: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 8rem;
  justify-content: space-between;
}

#menuToggle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1998;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 1rem;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
}

#menuToggle input {
  display: block;
  width: 4rem;
  height: 4rem;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 1999;
  -webkit-touch-callout: none;
  top: -0.5rem;
  left: -2.2rem;
}

#menuToggle span {
  display: flex;
  width: 3.3rem;
  height: 0.2rem;
  margin-bottom: 1rem;
  position: relative;
  background: white;
  border-radius: 0.3rem;
  z-index: 1998;
  transform-origin: 0.35rem -0.05rem;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  left: -4.2rem;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(-45deg) translate(0, 0px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 1;
  transform: rotate(45deg);
  transform-origin: -0.25rem 0.6rem;
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(0deg) scale(0.2, 0.2);
  opacity: 0;
}

#sidenav {
  width: 100%;
  left: 100%;
  text-align: left;
}

.sidenav {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav-bg {
  background-color: var(--tattoo-dark-gray);
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
}

.row {
  margin: 0;
}

.opening-wrapper {
  margin: 10rem 0;
}

.cta-btn {
  margin: 0 4rem;
}

.cta-btn:first-of-type {
  margin: 0;
}

.nav-link {
  margin: 2.5rem 0;
}

.legal-link {
  margin: 0 2rem;
}

.legal-link:first-of-type {
  margin: 0;
  margin-left: 1.5rem;
}

/** Socialmedia Wrapper **/
.socialmedia-wrapper::before {
  position: absolute;
  content: "";
  width: 0.2rem;
  height: 200%;
  background-color: white;
  right: 7.5rem;
  bottom: -14rem;
}

.socialmedia-wrapper .fa {
  font-size: 4rem;
  margin-left: 7rem;
  margin-right: 6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.socialmedia-wrapper-inner {
  padding: 5rem 0;
}

.logo {
  margin: 4rem 0;
  margin-left: 1rem;
}

@media screen and (min-width: 75em) {
  .h-xl-100 {
    height: 100%;
  }
}

@media screen and (max-width: 75em) {
  .large-size {
    display: none;
  }
  .medium-size {
    display: flex;
  }

  .legal-link:first-of-type {
    margin: 0;
  }

  .legal-link {
    margin-bottom: 3rem;
  }

  .opening-wrapper {
    margin: 3rem 0;
  }

  .logo-call-to-action-wrapper {
    padding: 0 5vw;
  }

  .link-container {
    padding-left: 5vw;
  }

  .nav-link {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 37.5em) {
  .left-logo {
    left: 2rem;
  }

  .left-logo img {
    width: 14rem;
  }

  .socialmedia-wrapper .fa {
    font-size: 3rem;
    margin-left: 5rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .socialmedia-wrapper-inner {
    padding: 2rem 0;
    margin-top: 8rem;
  }

  .socialmedia-wrapper::before {
    height: 170%;
    right: 3.4rem;
    bottom: -13rem;
  }

  #menuToggle input {
    left: 2rem;
  }

  #menuToggle span {
    left: 0;
  }

  .link-container {
    padding-left: 0;
  }

  .nav-link {
    margin: 0;
  }

  .cta-btn {
    margin: 2rem 0;
  }

  .call-to-action {
    flex-direction: column;
  }
}
</style>
