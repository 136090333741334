<template>
  <section id="ctCallToAction" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <div v-if="body.content" class="content">
        <article>
          <vue-markdown :source="body.content"></vue-markdown>
        </article>
      </div>
      <div class="row">
        <div
          class="col d-flex justify-content-center"
          v-for="link in body.link"
          :key="link.id"
        >
          <Button class="btn" :button="link" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Button from "./element/Button.vue";

export default {
  name: "ContentTypeCallToAction",
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
    Button,
  },
};
</script>

<style scoped>
.content {
  margin-bottom: 6rem;
}
</style>
