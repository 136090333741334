var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contact.data.attributes)?_c('main',{attrs:{"id":"contact"}},[_vm._l((_vm.contact.data.attributes.body),function(body,index){return _c('div',{key:body.id},[(body.__typename === 'ComponentContentTypesCtContact')?_c('div',[_c('ContentTypeContact',{attrs:{"body":body,"pos":index}})],1):_vm._e(),(body.__typename === 'ComponentContentTypesCtHeroSection')?_c('div',[_c('ContentTypeHeroSection',{attrs:{"body":body,"pos":index}})],1):_vm._e()])}),_c('div',{staticClass:"bg-darkGray"},[(_vm.submitstatus == 'SUCCESS')?_c('div',[_c('div',{staticClass:"popup-container"},[_c('div',{staticClass:"popup-img"},[(_vm.submitsuccess)?_c('lord-icon',{attrs:{"delay":"600000","trigger":"loop","src":"/assets/graphics/contact-checkmark-true.json"}}):_c('lord-icon',{attrs:{"delay":"600000","trigger":"loop","src":"/assets/graphics/contact-checkmark-false.json"}})],1),_c('div',{staticClass:"popup-text text"},[(_vm.submitsuccess)?_c('div',[_c('h3',{staticClass:"popup-head text-white"},[_vm._v(" Anfrage wurde erfolgreich zugestellt ")]),_c('p',{staticClass:"popup-body text-white"},[_vm._v(" Vielen Dank für Ihre Nachricht, wir werden uns unverzüglich bei Ihnen melden. ")])]):_c('div',[_c('h3',{staticClass:"popup-head text-white"},[_vm._v(" Anfrage wurde nicht zugestellt ")]),_c('p',{staticClass:"popup-body text-white"},[_vm._v(" Etwas ist schiefgelaufen. Bitte veruschen Sie es erneut. ")])])]),_c('button',{staticClass:"popup-btn",on:{"click":_vm.closePopUp}})])]):_vm._e(),_c('div',[(_vm.contact.data.attributes.content)?_c('div',{staticClass:"container"},[_c('article',[_c('vue-markdown',{attrs:{"source":_vm.contact.data.attributes.content}})],1)]):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text",class:{
              'is-invalid': _vm.$v.firstname.$error,
            }},[_vm._v("Vorname* "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.firstname.$model),expression:"$v.firstname.$model",modifiers:{"trim":true}}],staticClass:"text",attrs:{"id":"vname","type":"text","name":"firstname","placeholder":"Vorname"},domProps:{"value":(_vm.$v.firstname.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.firstname, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.firstname.required)?_c('span',[_vm._v("Das ist ein Pflichtfeld! ")]):_vm._e()])]),_c('br'),_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text",class:{
              'is-invalid': _vm.$v.name.$error,
            }},[_vm._v("Name* "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"text",attrs:{"id":"name","type":"text","name":"lastname","placeholder":"Name"},domProps:{"value":(_vm.$v.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.name.required)?_c('span',[_vm._v("Das ist ein Pflichtfeld! ")]):_vm._e()])]),_c('br'),_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text"},[_vm._v("Geburtstag "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.birthday),expression:"birthday"}],staticClass:"text",attrs:{"id":"date","type":"text","name":"birthday","placeholder":"Geburtstag"},domProps:{"value":(_vm.birthday)},on:{"input":function($event){if($event.target.composing){ return; }_vm.birthday=$event.target.value}}})])]),_c('br'),_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text",class:{
              'is-invalid': _vm.$v.email.$error,
            }},[_vm._v("E-Mail* "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],staticClass:"text",attrs:{"id":"email","type":"text","name":"emailadress","placeholder":"E-Mail"},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Das ist ein Pflichtfeld!")]):_vm._e()])]),_c('br'),_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text"},[_vm._v("Telefon "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.phonenumber),expression:"phonenumber",modifiers:{"trim":true}}],staticClass:"text",attrs:{"id":"phone","type":"tel","name":"phonennumber","placeholder":"Telefon"},domProps:{"value":(_vm.phonenumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phonenumber=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('br'),_c('div',{staticClass:"input-container"},[_c('label',{staticClass:"text",class:{
              'is-invalid': _vm.$v.message.$error,
            }},[_vm._v("Nachricht* "),_c('div',{staticClass:"textarea-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.message.$model),expression:"$v.message.$model",modifiers:{"trim":true}}],staticClass:"text",attrs:{"id":"message","maxlength":"500","placeholder":"Schreib uns eine Nachricht"},domProps:{"value":(_vm.$v.message.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.message, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"charcount-container"},[_c('span',{staticClass:"charcount text"},[_vm._v(_vm._s(_vm.message.length))]),_c('span',{staticClass:"charcount text"},[_vm._v("/"+_vm._s(_vm.maxcharacter))])])])]),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.message.required)?_c('span',[_vm._v("Das ist ein Pflichtfeld! ")]):_vm._e()]),_c('br')]),_c('br'),_c('label',{staticClass:"text privacy-text"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.privacy.$model),expression:"$v.privacy.$model",modifiers:{"trim":true}}],class:{
              'is-invalid': _vm.$v.privacy.$error,
            },attrs:{"id":"privacy","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.privacy.$model)?_vm._i(_vm.$v.privacy.$model,null)>-1:(_vm.$v.privacy.$model)},on:{"change":function($event){var $$a=_vm.$v.privacy.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.privacy, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.privacy, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.privacy, "$model", $$c)}}}}),_vm._v(" Hiermit bestätigen Sie unsere Datenschutzbestimmungen. Mehr Informationen finden Sie in der "),_c('Button',{attrs:{"button":_vm.contact.data.attributes.link}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.privacy.sameAs)?_c('span',{attrs:{"id":"invalid-checkbox"}},[_vm._v("Das ist ein Pflichtfeld!")]):_vm._e()])],1),_c('label',{staticClass:"text lbl-privacy"},[_vm._v("* Pflichtfelder")]),_c('button',{staticClass:"btn-primary-sw",attrs:{"id":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Absenden ")])])])]),_c('FooterSmall',{attrs:{"legalLinks":_vm.footer.legalLinks}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }