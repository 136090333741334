<template>
  <section id="ctTextWImage" class="bg-darkGray">
    <div class="container">
      <div v-if="body.headline">
        <h2 class="headline">
          <span>{{ body.headline }}</span>
        </h2>
      </div>
      <div class="txtImageSection d-inline-block">
        <img
          v-if="body.image"
          class="image"
          :src="url + body.image.data.attributes.url"
          :alt="body.image.data.attributes.alternativeText"
        />
        <article v-if="body.content">
          <vue-markdown :source="body.content"></vue-markdown>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getStrapiUrl } from "../helper";

export default {
  name: "ContentTypeTextWImage",
  data() {
    return {
      url: getStrapiUrl(),
    };
  },
  props: {
    body: {
      type: Object,
    },
    pos: {
      type: Number,
    },
  },
  components: {
    VueMarkdown,
  },
};
</script>

<style scoped>
.image {
  float: right;
  max-width: 50%;
  min-width: 45%;
  height: auto;
  margin: 0 0 1rem 2rem;
}

.txtImageSection {
  text-align: justify;
}

@media screen and (max-width: 48em) {
  .image {
    max-width: unset;
    min-width: unset;
    float: unset;
    width: 100%;
    height: auto;
    margin: 0 0 2rem 0;
  }
}
</style>
