var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"landingHero"}},[_c('div',{staticClass:"bg-darkGray position-relative background",attrs:{"id":"landing-desktop"}},[_vm._l((_vm.landingText),function(text,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currIndex),expression:"index === currIndex"}],key:text.id},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currIndex),expression:"index === currIndex"}],staticClass:"bg-img position-absolute",style:({
          backgroundImage: ("linear-gradient(90deg, #1F1F1F 42%, rgba(30, 30, 30, 0) 100%), url(" + (_vm.url + text.backgroundImage.data.attributes.url) + ")"),
        })}),_c('div',{staticClass:"row position-relative"},[_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('div',{staticClass:"socialmedia-wrapper position-relative text-center bg-darkGray"},[_c('div',{staticClass:"bg-darkGray socialmedia-wrapper-inner position-relative"},[_c('a',{attrs:{"href":_vm.navData.facebook,"target":"_blank"}},[_c('i',{staticClass:"fa fa-brands fa-facebook-f text-white"})]),_c('br'),_c('a',{attrs:{"href":_vm.navData.instagram,"target":"_blank"}},[_c('i',{staticClass:"fa fa-brands fa-instagram text-white"})])])])]),_c('div',{staticClass:"col"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.url + _vm.logo.data.attributes.url,"alt":_vm.logo.data.attributes.alternativeText}}),_c('h1',[_vm._v(_vm._s(text.headline))]),_c('article',{staticClass:"text-white info-text"},[_c('vue-markdown',{attrs:{"source":text.text}})],1),_c('Button',{staticClass:"cta-link",attrs:{"button":text.link}})],1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-flex justify-content-center h-100 flex-column"},_vm._l((_vm.navData.navLink),function(link){return _c('Button',{key:link.id,staticClass:"text-end nav-link",attrs:{"button":Object.assign({}, link,
                {style: 'link'})}})}),1)])])])}),_c('div',{staticClass:"d-flex flex-row position-relative justify-content-between carousel"},[_c('div',{staticClass:"d-flex align-items-end pagination"},[_vm._l((_vm.landingText.length),function(n){return _c('div',{key:n,staticClass:"text-white m-4",attrs:{"role":"button"},on:{"click":function($event){_vm.currIndex = n - 1}}},[(_vm.currIndex + 1 === n)?_c('img',{attrs:{"src":"/assets/graphics/pagination-active.svg","alt":"Pagination dot"}}):_c('img',{staticClass:"pagination-hover",attrs:{"src":"/assets/graphics/pagination-normal.svg","alt":"Pagination dot"}})])}),_vm._m(0)],2),_c('div',_vm._l((_vm.landingCarousel),function(carousel,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currCarouselIndex),expression:"index === currCarouselIndex"}],key:carousel.id},[_c('div',{staticClass:"d-flex"},[_vm._l((carousel.images.data),function(img){return _c('img',{key:img.id,staticClass:"carousel-img",attrs:{"src":_vm.url + img.attributes.url,"alt":img.attributes.alternativeText}})}),_c('div',{staticClass:"d-flex bg-darkGray carousel-info-control-wrapper"},[_c('div',{staticClass:"d-flex flex-column justify-content-between carousel-info-wrapper"},[_c('h6',{staticClass:"carousel-headline"},[_vm._v(_vm._s(carousel.headline))]),_c('Button',{attrs:{"button":{
                    page: carousel.seeMore,
                    url: null,
                    label: 'Mehr erfahren',
                    style: 'link',
                  }}})],1),_c('div',{staticClass:"carousel-controls-wrapper"},[_c('img',{staticClass:"bg-darkGray fa-rotate-180",attrs:{"src":"/assets/graphics/image-gallery-arrow.svg","alt":"Arrow"},on:{"click":function($event){index > 0
                      ? _vm.currCarouselIndex--
                      : (_vm.currCarouselIndex = _vm.landingCarousel.length - 1)}}}),_c('img',{staticClass:"bg-darkGray",attrs:{"src":"/assets/graphics/image-gallery-arrow.svg","alt":"Arrow"},on:{"click":function($event){index === _vm.landingCarousel.length - 1
                      ? (_vm.currCarouselIndex = 0)
                      : _vm.currCarouselIndex++}}})])])],2)])}),0)])],2),_c('div',{staticClass:"bg-darkGray",attrs:{"id":"landing-mobile"}},[_vm._l((_vm.landingText),function(text,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currIndex),expression:"index === currIndex"}],key:text.id,staticClass:"position-relative"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currIndex),expression:"index === currIndex"}],staticClass:"bg-img position-absolute",style:({
          backgroundImage: ("linear-gradient(360deg, #1F1F1F 0%, rgba(30, 30, 30, 0) 100%), url(" + (_vm.url + text.backgroundImage.data.attributes.url) + ")"),
        })}),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"ls-mobile-inner"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.url + _vm.logo.data.attributes.url,"alt":_vm.logo.data.attributes.alternativeText}}),_c('div',{staticClass:"d-flex align-items-end pagination"},[_vm._l((_vm.landingText.length),function(n){return _c('div',{key:n,staticClass:"text-white m-4",class:{ 'ms-0': n === 1 },attrs:{"role":"button"},on:{"click":function($event){_vm.currIndex = n - 1}}},[(_vm.currIndex + 1 === n)?_c('img',{attrs:{"src":"/assets/graphics/pagination-active.svg","alt":"Pagination dot"}}):_c('img',{staticClass:"pagination-hover",attrs:{"src":"/assets/graphics/pagination-normal.svg","alt":"Pagination dot"}})])}),_vm._m(1,true)],2),_c('h1',[_vm._v(_vm._s(text.headline))]),_c('article',{staticClass:"text-white info-text"},[_c('vue-markdown',{attrs:{"source":text.text}})],1),_c('Button',{staticClass:"cta-link",attrs:{"button":text.link}})],1)])])}),_vm._l((_vm.landingCarousel),function(carousel,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.currCarouselIndex),expression:"index === currCarouselIndex"}],key:carousel.id,staticClass:"ls-mobile-inner"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"carousel-controls-wrapper"},[_c('div',{staticClass:"d-flex carousel-head-mobile"},[_c('h6',{staticClass:"carousel-headline"},[_vm._v(_vm._s(carousel.headline))]),_c('div',[_c('img',{staticClass:"bg-darkGray fa-rotate-180",attrs:{"src":"/assets/graphics/image-gallery-arrow.svg","alt":"Arrow"},on:{"click":function($event){index > 0
                    ? _vm.currCarouselIndex--
                    : (_vm.currCarouselIndex = _vm.landingCarousel.length - 1)}}}),_c('img',{staticClass:"bg-darkGray",attrs:{"src":"/assets/graphics/image-gallery-arrow.svg","alt":"Arrow"},on:{"click":function($event){index === _vm.landingCarousel.length - 1
                    ? (_vm.currCarouselIndex = 0)
                    : _vm.currCarouselIndex++}}})])])]),_c('div',{staticClass:"flex"},_vm._l((carousel.images.data),function(img){return _c('img',{key:img.id,staticClass:"carousel-img medium-size",attrs:{"src":_vm.url + img.attributes.url,"alt":img.attributes.alternativeText}})}),0),_c('img',{staticClass:"carousel-img small-size",attrs:{"src":_vm.url + carousel.images.data[0].attributes.url,"alt":carousel.images.data[0].attributes.alternativeText}}),_c('div',{staticClass:"d-flex bg-darkGray carousel-info-control-wrapper"},[_c('div',{staticClass:"d-flex flex-column justify-content-between carousel-info-wrapper"},[_c('Button',{attrs:{"button":{
                page: carousel.seeMore,
                url: null,
                label: 'Mehr erfahren',
                style: 'link',
              }}})],1)])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meter"},[_c('span',[_c('span',{staticClass:"progress"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meter"},[_c('span',[_c('span',{staticClass:"progress"})])])}]

export { render, staticRenderFns }