<template>
  <div
    v-if="visible"
    class="overlay-container"
    :class="{ scrollable: scrollable }"
  >
    <slot></slot>
    <button class="overlay-btn" @click="hide"></button>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  },
  props: {
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.scrollable {
  overflow-y: scroll;
}
.overlay-container {
  position: fixed;
  top: 10%;
  margin: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  width: 80rem;
  max-height: 80vh;
  background-color: var(--tattoo-darker-gray);
  border-radius: 1rem;
  padding: 2.4rem;
  box-sizing: initial;
}
.overlay-btn {
  background: url(/assets/graphics/image-gallery-cross.svg) no-repeat;
  background-color: var(--tattoo-darker-gray);
  background-size: 3rem 3rem;
  background-position: center;
  border: none;
  padding: 1rem 1rem;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  top: 3%;
  right: 1%;
  width: 4rem;
  height: 4rem;
}

@media screen and (max-width: 81.25em) {
  .overlay-container {
    width: 60vw;
  }
}

@media screen and (max-width: 40.625em) {
  .overlay-container {
    width: 80vw;
  }
}
</style>
