var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-container"},[(_vm.button.linkHeadline)?_c('h4',{staticClass:"linkHead"},[_vm._v(" "+_vm._s(_vm.button.linkHeadline)+" ")]):_vm._e(),_c('button',{staticClass:"button",attrs:{"type":"button"}},[(
        _vm.button.page.data &&
        _vm.button.page.data.attributes !== null &&
        (_vm.button.url == '' || _vm.button.url == null)
      )?_c('router-link',{class:{
        'btn-primary-sw': _vm.button.style == 'primary',
        'btn-secondary-sw': _vm.button.style == 'secondary',
        'btn-link-sw': _vm.button.style == 'link',
        'btn-text-sw': _vm.button.style == 'text',
        'btn-dark-primary-sw': _vm.button.style == 'dark_primary',
        'btn-blue-icon-sw': _vm.button.style == 'blue_icon',
        'btn-nav-link': _vm.button.style == 'nav_link',
      },attrs:{"to":{ path: '/' + _vm.button.page.data.attributes.slug }}},[_c('label',[_vm._v(" "+_vm._s(_vm.button.label)+" ")]),(
          _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
        )?_c('img',{staticClass:"icon",attrs:{"src":_vm.url + _vm.button.icon.data.attributes.url,"alt":_vm.button.icon.data.attributes.alternativeText}}):_vm._e(),(
          _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
        )?_c('lord-icon',{attrs:{"target":"a","trigger":"hover","src":_vm.url + _vm.button.icon.data.attributes.url}}):_vm._e()],1):_vm._e(),(
        _vm.button.url !== null &&
        _vm.button.url !== '' &&
        (_vm.button.page === null || _vm.button.page.data === null)
      )?_c('a',{class:{
        'btn-primary-sw rippel': _vm.button.style == 'primary',
        'btn-secondary-sw': _vm.button.style == 'secondary',
        'btn-link-sw': _vm.button.style == 'link',
        'btn-text-sw': _vm.button.style == 'text',
        'btn-dark-primary-sw': _vm.button.style == 'dark_primary',
        'btn-blue-icon-sw': _vm.button.style == 'blue_icon',
        'btn-nav-link': _vm.button.style == 'nav_link',
      },attrs:{"href":_vm.button.url}},[_c('label',[_vm._v(" "+_vm._s(_vm.button.label)+" ")]),(
          _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() !== '.json'
        )?_c('img',{staticClass:"icon",attrs:{"src":_vm.url + _vm.button.icon.data.attributes.url,"alt":_vm.button.icon.data.attributes.alternativeText}}):_vm._e(),(
          _vm.button.icon &&
          _vm.button.icon.data &&
          _vm.button.icon.data.attributes.ext.toLowerCase().trim() == '.json'
        )?_c('lord-icon',{attrs:{"target":"a","trigger":"hover","src":_vm.url + _vm.button.icon.data.attributes.url}}):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }